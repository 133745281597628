import React, { Component } from "react";
import { FadeInSection } from "../helpers/FadeInSection";

class ImageVideo extends Component {
  render() {
    return (
      <div
        id="section-imageVideo"
        ref={this.props.refName}
        className="section imagevideo"
      >
        <FadeInSection>
          <div className="section-imageVideo-content">
            <h2>Imagevideo der Reportingplattform</h2>
            <div className="video-embed-container">
              <iframe
                className="video-embed"
                src="https://www.youtube-nocookie.com/embed/bl6v2jFYtVI"
                title="YouTube video player - Imagevideo Reportingplattform des Bundes"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          <div className="imageVideo-accent" />
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default ImageVideo;
