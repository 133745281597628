import React, { Component } from "react";
import "./App.scss";

import Header from "./components/Header";
import Home from "./components/Home";
import ReportingPlatform from "./components/ReportingPlatform";
import References from "./components/References";
import Projects from "./components/Projects";
import Benefits from "./components/Benefits";
import Technology from "./components/Technology";
import Roadmap from "./components/Roadmap";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ImageVideo from "./components/ImageVideo";

class App extends Component {
  state = {
    hideMobileMenu: true,
  };

  sectionHome = React.createRef();
  sectionPlatform = React.createRef();
  sectionReferences = React.createRef();
  sectionProjects = React.createRef();
  sectionBenefits = React.createRef();
  sectionTechnology = React.createRef();
  sectionRoadmap = React.createRef();
  sectionContact = React.createRef();

  scrollTo = (e, refName) => {
    e.preventDefault();
    var scroll = 0;
    if (refName !== "sectionHome")
      scroll = this[refName].current.offsetTop - 60;

    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    //check if IE
    if (isIE) {
      document.documentElement.scrollTop = scroll;
    } else {
      window.scrollTo({ top: scroll, behavior: "smooth" });
    }

    setTimeout(() => this.toggleMobileMenu(true), 100);
  };

  toggleMobileMenu = (close) => {
    this.setState({ hideMobileMenu: close });
  };

  render() {
    return (
      <div className="landing-page">
        <Header
          scrollTo={this.scrollTo}
          toggleMobileMenu={this.toggleMobileMenu}
          hideMobileMenu={this.state.hideMobileMenu}
        />
        <div className="main">
          <Home refName={this.sectionHome} />
          <ReportingPlatform refName={this.sectionPlatform} />
          { <ImageVideo /> }
          <References refName={this.sectionReferences} />
          <Projects refName={this.sectionProjects} />
          <Benefits refName={this.sectionBenefits} />
          <Technology refName={this.sectionTechnology} />
          <Roadmap refName={this.sectionRoadmap} />
          <Contact refName={this.sectionContact} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
