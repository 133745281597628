import React, { Component } from 'react';
// import logo from '../images/logo_white.png';
import logoBMAW from '../images/logos/BMAW_Logo_white.png';
import logoBMF from '../images/logos/BMF_Logo_weiss.png';
import logoBMJ from '../images/logos/BMJ_Logo_weiss.png';
import ulrike from '../images/domany-funtan.jpg';
import sigi from '../images/kabasser.jpg';
import wolfgang from '../images/ebner.jpg';
import { FadeInSection } from '../helpers/FadeInSection';

class References extends Component {
  state = {
    active: 0,
  };

  testimonials = [
    {
      title: 'Die Digitalisierung der Verwaltung modern steuern',
      text: [
        'Als Chief Digital Officer (CDO) des Bundes leite ich die Runde der CDOs aus allen Ressorts, um die Digitalisierung, das E-Government und die IT-Konsolidierung in der gesamten Bundesverwaltung voranzutreiben. Damit soll Österreich neuerlich ein Stück moderner und kostengünstiger gemanagt werden. Hier eignet sich die neue Reporting Plattform hervorragend, den Stand der unterschiedlichen Großprojekte auf einen Blick erfassen und daraus spezifische Maßnahmen ableiten zu können.',
        'Gleichzeitig lassen sich mit dem Management-Tool die Datenschätze der Verwaltung heben sowie die Kennzahlen der Ressorts exakt zusammenfassen, um den Lauf der Digitalisierungsvorhaben in Richtung Gesellschaft und Wirtschaft gesamtheitlich zu überblicken und täglich über die Weiterentwicklung informiert zu sein. Damit liefert die Reporting Plattform Entscheidungsträger:innen örtlich unabhängig und in entsprechender Aktualität die steuerungsrelevanten Kennzahlen als fundierte Grundlage für verantwortungsvolles Handeln.',
      ],
      name: 'Sektionschef Mag. Wolfgang Ebner',
      image: wolfgang,
      imageCredit: 'Fotocredit: © Matthias Dolenc (BMAW)',
    },
    {
      title: 'ZDF – Zahlen, Daten, Fakten mit reportingplattform.gv.at!',
      text: [
        'In Zusammenarbeit mit dem Bundeskanzleramt Österreich (BKA), Sektion VII, Abteilung VII/3 - Informationsmanagement und Services, können wir schon länger anonymisierte Gruppen-Analysen zu den digitalen Kompetenzchecks von fit4internet.at auf der Reporting Plattform nutzen. Die gemeinsam entwickelten, aggregierten Auswertungen erlauben die Ableitung von bedarfsorientierten Maßnahmen wie beispielsweise die Entwicklung weiterer, spezifischer Erfassungsinstrumente in einzelnen Kompetenzbereichen.  ',
        'So wurde aus den 10.000 Datensätze aus dem CHECK Digitale Alltagskompetenz rasch sichtbar, dass im Kompetenzbereich 4. Sicherheit gemäß dem Digitalen Kompetenzmodell für Österreich – DigComp 2.2. AT Handlungsbedarf besteht. Auf Basis der Analysen wurden die f4i-Instrumente secCHECK und secQUIZ zur Erfassung digitaler Kompetenzen im Kompetenzbereich Sicherheit entwickelt.',
        'Die Einblicke in die Zahlen, Daten und Fakten aus unseren Kompetenzerfassungen, die uns die Reporting Plattform bietet, sind in der Qualität und Tiefe einzigartig in Österreich und sowohl für die öffentliche Hand als auch für unsere Projektarbeit eine hervorragende Entscheidungsgrundlage.',
      ],
      name: 'Mag.a Ulrike Domany-Funtan, MBA, Generalsekretärin, fit4internet',
      link: 'https://www.fit4internet.at/',
      image: ulrike,
      imageCredit: 'Fotocredit: © Sabine Klimpt',
    },
    {
      title:
        'Die Berichte landen in der Hand- oder Hosentasche der Empfängerin/des Empfängers!',
      text: [
        'Im Controlling war ich es gewohnt, dass monatlich Berichte generiert, versendet, bzw. abgelegt wurden, welche nur zu einem geringen Maße konsumiert wurden. Wir haben unsere Berichtsempfängerinnen und Berichtsempfänger nicht entsprechend erreicht.',
        'Mit der Entwicklung der Reporting Plattform ist es uns gelungen die Datenübernahme, die Anreicherung von übernommenen Daten mit Zusatzinformationen, die so wichtige Interpretation der Daten und vor allem das Bereitstellen von Daten in eine moderne und intuitive Plattform zu transferieren. Dabei war es uns wichtig, einen aktuellen Architektur-Stack einzusetzen, welcher  hohe Flexibilität und eine kurze Time to market sicherstellt. Grundsätze der Reporting Plattform: Single Point of Contact, Single Point of truth, Time to market und dass wir mit unseren Anwendungen und den Daten "in der Handtasche/Hosentasche der Berichtsempfängerinnen/Berichtsempfänger" landen.',
        'Um dies zu erreichen ist es notwendig, Partnerinnen und Partner auf allen Ebenen zu gewinnen und mit Vertrauen zusammen zu arbeiten. An dieser Stelle möchte ich meinen Dank an alle Auftraggeberinnen und Auftraggeber, Partnerinnen und Partner, sowie Kundinnen und Kunden richten, die mit mir den bisherigen Weg beschritten haben. Herzlichen Dank!',
      ],
      name: 'MinRat Mag.(FH) Siegfried Kabasser, BKA',
      link: 'https://www.bka.gv.at',
      image: sigi,
      imageCredit: 'Fotocredit: © Kabasser Consulting',
    },
  ];

  showTestimonial = (testimonialNr) => {
    this.setState({ active: testimonialNr, slide: true });
  };

  getTestimonialMargin = (index) => {
    var marginLeft = 0;
    var marginRight = 0;

    if (index > this.state.active) {
      marginRight = -100;
    } else if (index < this.state.active) {
      marginLeft = -100;
    }

    return [marginLeft, marginRight];
  };

  componentDidMount = () => {};

  render() {
    var testimonials = this.testimonials.map((item, index) => {
      var margins = this.getTestimonialMargin(index);
      var marginL = margins[0] + '%';
      var marginR = margins[1] + '%';

      var text = item.text.map((item, index) => <p key={index}>{item}</p>);

      return (
        <div
          key={index}
          className={'quote-content'}
          style={{ marginLeft: marginL, marginRight: marginR }}
        >
          <h3>{item.title}</h3>
          {text}

          <div
            className={'description-wrapper' + (item.image ? ' has-image' : '')}
          >
            <div className="accent-line" />
            {item.image ? (
              <>
                <div className="quote-portrait">
                  <img src={item.image} alt={item.name} />
                </div>
              </>
            ) : null}
            <div className="description">
              <h4>{item.name}</h4>

              {item.image ? (
                <div>
                  <a tabIndex="-1" href={item.link}>
                    {item.link}
                  </a>
                </div>
              ) : null}
              <div className="credit">{item.imageCredit}</div>
            </div>
          </div>
        </div>
      );
    });

    var quoteBtns = this.testimonials.map((item, index) => (
      <button
        key={index}
        aria-hidden
        className={'quote-btn' + (this.state.active === index ? ' active' : '')}
        onClick={() => this.showTestimonial(index)}
        aria-label={'Testimonial von ' + item.name}
      />
    ));

    return (
      <div
        id="section-testimonials"
        ref={this.props.refName}
        className="references"
      >
        <div className="logos-wrapper">
          <img
            src={logoBMAW}
            className="reference-logo"
            alt="BMAW Logo"
            title="BMAW Logo"
          />
          <img
            src={logoBMF}
            className="reference-logo"
            alt="BMF Logo"
            title="BMF Logo"
          />
          <img
            src={logoBMJ}
            className="reference-logo"
            alt="BMJ Logo"
            title="BMJ Logo"
          />
        </div>

        <FadeInSection>
          <div className="quote-wrapper">
            <div className="quote">
              <div>
                <div className="quote-icon">"</div>
              </div>
              <div>
                <div className="testimonials">{testimonials}</div>
                {quoteBtns.length > 1 ? (
                  <div className="quote-slider">{quoteBtns}</div>
                ) : null}
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default References;
