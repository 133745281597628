import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import logo from '../images/logo.png';
import Scrollspy from 'react-scrollspy';
import { Bars, Times } from '../helpers/svgs';

class Header extends Component {
  state = {
    prevScrollPos: 0,
    borderedHeader: false,
    hideHeader: false,
  };

  navigationItems = (
    <div className="navigation-items" role="navigation">
      <div>
        <Scrollspy
          items={[
            'section-home',
            'section-reporting-plattform',
            'section-testimonials',
            'section-projekte',
            'section-vorteile',
            'section-technik',
            'section-roadmap',
            'section-kontakt',
          ]}
          currentClassName="current-section"
        >
          <li>
            <a
              role="menuitem"
              href="#section-home"
              onClick={(e) => this.props.scrollTo(e, 'sectionHome')}
            >
              Home
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-reporting-plattform"
              onClick={(e) => this.props.scrollTo(e, 'sectionPlatform')}
            >
              Reporting Plattform
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-testimonials"
              onClick={(e) => this.props.scrollTo(e, 'sectionReferences')}
            >
              Testimonials
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-projekte"
              onClick={(e) => this.props.scrollTo(e, 'sectionProjects')}
            >
              Projekte
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-vorteile"
              onClick={(e) => this.props.scrollTo(e, 'sectionBenefits')}
            >
              Vorteile
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-technik"
              onClick={(e) => this.props.scrollTo(e, 'sectionTechnology')}
            >
              Technik
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-roadmap"
              onClick={(e) => this.props.scrollTo(e, 'sectionRoadmap')}
            >
              Roadmap
            </a>
          </li>
          <li>
            <a
              role="menuitem"
              href="#section-kontakt"
              onClick={(e) => this.props.scrollTo(e, 'sectionContact')}
            >
              Kontakt
            </a>
          </li>
        </Scrollspy>
      </div>
    </div>
  );

  onScroll = () => {
    var currentScrollPos = window.pageYOffset;
    var hide = false;
    var bordered = false;

    if (currentScrollPos > 10) {
      bordered = true;
    }

    if (this.state.prevScrollPos > currentScrollPos) {
      hide = false;
    } else if (currentScrollPos > 250) {
      hide = true;
    }

    this.setState({
      prevScrollPos: currentScrollPos,
      hideHeader: hide,
      borderedHeader: bordered,
    });
  };

  componentDidMount() {
    if (/*@cc_on!@*/ false || !!document.documentMode)
      //check if browser is IE
      window.addEventListener('scroll', debounce(this.onScroll, 30));
    else window.addEventListener('scroll', this.onScroll, 10);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  };

  render() {
    let navigationItems = this.navigationItems;

    return (
      <header
        className={
          'header' +
          (this.state.borderedHeader ? ' border-bottom' : '') +
          (this.state.hideHeader & this.props.hideMobileMenu
            ? ' hide-to-top'
            : '') +
          (this.props.hideMobileMenu ? '' : ' fixed border-bottom')
        }
      >
        <div className="header-content-wrapper">
          <div className="centering-wrapper">
            <img
              className={'logo'}
              src={logo}
              alt="Reporting Plattform Logo"
              title="Reporting Plattform Logo"
            />
          </div>
          {navigationItems}
          <div className="centering-wrapper">
            <a
              className="cyan-btn power-bi-demo-btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.powerbi.com/view?r=eyJrIjoiZThmMzUxOTQtMDU3NC00YzE2LWEwYzctN2JkOWRiZDk5MmM5IiwidCI6IjczYTMxYjAxLTc2NjctNDAyNC05NWNhLTZjN2E0ZjdjNDk5OCIsImMiOjl9"
            >
              Aktuelle Zahlen
            </a>

            <button
              data-dismiss="modal"
              aria-label={
                this.props.hideMobileMenu ? 'Menü öffnen' : 'Menü schließen'
              }
              className={
                'mobile-menu-btn' + (this.props.hideMobileMenu ? '' : ' open')
              }
              onClick={() =>
                this.props.toggleMobileMenu(!this.props.hideMobileMenu)
              }
            >
              {this.props.hideMobileMenu ? Bars : Times}
            </button>
          </div>
        </div>

        <div
          className={
            'mobile-menu' + (this.props.hideMobileMenu ? ' hidden' : '')
          }
        >
          {navigationItems}

          <div className="power-bi-link-wrapper">
            <a
              title="Link zum Power-BI Dashboard mit aktuellen Zahlen zur Reporting Plattform"
              className="lime-btn power-bi-demo-btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.powerbi.com/view?r=eyJrIjoiZThmMzUxOTQtMDU3NC00YzE2LWEwYzctN2JkOWRiZDk5MmM5IiwidCI6IjczYTMxYjAxLTc2NjctNDAyNC05NWNhLTZjN2E0ZjdjNDk5OCIsImMiOjl9"
            >
              Aktuelle Zahlen
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
