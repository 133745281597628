import React, { Component } from 'react';

import logo from '../images/logo_white.png';
import ImageModal from './ProjectDetailsPage/ImageModal';
import Imprint from './FooterModals.js/Imprint';
import DataPrivacy from './FooterModals.js/DataPrivacy';
import Accessibility from './FooterModals.js/Accessibility';

class Footer extends Component {
  state = {
    showImprintModal: false,
    showDataModal: false,
    showAccesibilityModal: false,
  };

  closeModal = () => {
    this.setState({
      showImprintModal: false,
      showDataModal: false,
      showAccesibilityModal: false,
    });
    this.toggleBodySidebar();
  };

  showModal = (type) => {
    if (type === 'imprint') this.setState({ showImprintModal: true });
    else if (type === 'data') this.setState({ showDataModal: true });
    else this.setState({ showAccesibilityModal: true });
    this.toggleBodySidebar();
  };

  toggleBodySidebar = () => {
    document.body.classList.toggle('modal-open');
  };

  escFunction = (event) => {
    if (
      event.keyCode === 27 &&
      (this.state.showDataModal || this.state.showImprintModal || this.state.showAccesibilityModal)
    ) {
      this.closeModal();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  render() {
    return (
      <div className="footer" id="footer">
        <div className="footer-logo-wrapper">
          <img src={logo} alt="Logo Reporting Plattform" title="Logo Reporting Plattform" />
        </div>
        <div className="footer-links-wrapper">
          <div className="footer-link">
            <button onClick={() => this.showModal('imprint')} href="#">
              Impressum
            </button>
          </div>
          <div className="footer-link">
            <button onClick={() => this.showModal('data')} href="#">
              Datenschutz
            </button>
          </div>
          <div className="footer-link">
            <button onClick={() => this.showModal('access')} href="#">
              Barrierefreiheit
            </button>
          </div>
        </div>
        <ImageModal>
          <Imprint
            visible={this.state.showImprintModal}
            closeImageModal={this.closeModal}
            openDataPrivacyModal={() => {
              this.closeModal();
              this.showModal('data');
            }}
            openAccessibilityModal={() => {
              this.closeModal();
              this.showModal('access');
            }}
          />
          <DataPrivacy visible={this.state.showDataModal} closeImageModal={this.closeModal} />
          <Accessibility
            visible={this.state.showAccesibilityModal}
            closeImageModal={this.closeModal}
          />
        </ImageModal>
      </div>
    );
  }
}

export default Footer;
