import React, { Component } from 'react';
import { Times } from '../../helpers/svgs';

class Imprint extends Component {
  render() {
    return (
      <div className={'imprint' + (this.props.visible ? '' : ' hide')} role="dialog">
        <div className="imprint-card">
          <button
            className="close-image-modal-btn"
            onClick={this.props.closeImageModal}
            data-dismiss="modal"
            aria-label="Schließen"
          >
            {Times}
          </button>
          <h2>Impressum und Offenlegung</h2>
          <h4>Anschrift</h4>
          <p>
            Bundeskanzleramt Österreich
            <br />
            Ballhausplatz 2, 1010 Wien
            <br />
            <a href="tel:+431531150" title="Telefonnummer">
            Tel.: (+43) 1 531 15-0
            </a>
          </p>
          <p>
            Das Bundeskanzleramt informiert mit seinen elektronischen Medien über alle
            steuerpolitischen und steuerrechtlichen Themen, die in seinem Aufgabengebiet angesiedelt
            sind.
          </p>
          <p>
            Auch wenn im Text aus Gründen der Textökonomie weibliche Formen wie "Steuerzahlerinnen"
            nicht explizit ausgeschrieben sind, beziehen sich alle personenbezogenen Formulierungen
            auf weibliche und männliche Personen.
          </p>
          <h4>UID-Nummer</h4>
          <p>UID-Nr.: ATU38159201</p>
          <p>EORI: ATEOS1000040217</p>

          <h4>Rechtliche Hinweise</h4>
          <p>
            <a href="#footer" onClick={this.props.openDataPrivacyModal}>
              Erklärung zum Datenschutz
            </a>
          </p>
          <h5>Urheberrechtsschutz - Copyright-Vermerk</h5>
          <p>© Bundeskanzleramt Österreich</p>
          <p>
            Das österreichische Bundeskanzleramt (BKA) betreibt diese Website zur
            Verbesserung des öffentlichen Zugangs zu Informationen über Aufgabenbereiche, Projekte
            und Initiativen des BKA.
          </p>
          <p>
            Die in diesem Internet-Angebot veröffentlichten Beiträge sind urheberrechtlich
            geschützt. Alle Rechte bleiben vorbehalten.
          </p>
          <p>Außer zu kommerziellen Zwecken ist bei Quellenangabe ein Nachdruck gestattet.</p>
          <p>
            Das Bundeskanzleramt (BKA) übernimmt für die Inhalte der Informationen auf
            dieser oder anderen Websites des BKA keine Haftung. Trotz unserer Bemühungen kann keine
            Gewähr für die Aktualität und Richtigkeit dieser Informationen übernommen werden. Soweit
            Fehler und Irrtümer bekannt werden, werden diese so schnell wie möglich korrigiert.
          </p>
          <p>
            Einige Dokumente auf der Website enthalten Hinweise auf Informationen anderer
            Organisationen, wie zB anderer österreichischer Bundesministerien oder den EU-Server.
            Für die Relevanz, Aktualität oder Richtigkeit dieser Informationen wird keine Gewähr
            übernommen.
          </p>
          <h5>Haftungsausschluss</h5>
          <p>
            Das österreichische Bundeskanzleramt (BKA) übernimmt keinerlei
            Verantwortung oder Haftung für die Richtigkeit und Aktualität der Angaben auf dieser
            Website. Die Angaben sind:
          </p>
          <ul>
            <li>
              nur Informationen allgemeiner Art, die nicht auf die besonderen Bedürfnisse bestimmter
              Personen oder Einrichtungen abgestimmt sind;
            </li>
            <li>nicht notwendigerweise umfassend, komplett, genau oder aktuell;</li>
            <li>
              manchmal mit externen Websites verbunden, die das BKA nicht beeinflussen kann und für
              die sie ebenfalls keine Verantwortung übernimmt;
            </li>
            <li>
              keine professionelle oder rechtliche Beratung (Wenn Sie eine besondere Beratung
              benötigen, sollten Sie immer eine Expertin/einen Experten oder Sachverständigen,
              jedenfalls eine mit der konkreten Sachlage vertraute Person, zu Rate ziehen).
            </li>
          </ul>
          <p>
            Wir möchten Störungen aufgrund technischer Probleme so gering wie möglich halten und
            empfehlen Ihnen, sämtliche Möglichkeiten zu nutzen, um solchen Störungen vorzubeugen. Es
            ist allerdings nicht ausgeschlossen, dass einige der Daten und Angaben auf unserer
            Website in nicht fehlerfreien Dateien oder Formaten angelegt/strukturiert sind, und wir
            können keine Gewährleistung oder Haftung dafür übernehmen, dass unser Dienst nicht
            unterbrochen oder anderweitig durch Störungen beeinträchtigt wird.
          </p>
          <p>
            Die IT des Bundeskanzleramts ist nach den strengen Sicherheitsvorschriften
            ISO 27001 zertifiziert. Zur Gewährleistung der Datensicherheit wird auf den Webseiten
            und Webservices der aktuelle Verschlüsselungsstandard Transport Layer Security (TLS)
            eingesetzt, der eine sichere Datenübertragung im Internet garantiert. Ältere
            Verschlüsselungsstandards, wie z.B. das Secure Socket Layer Protokoll (SSL), sowie
            ältere Verschlüsselungsalgorithmen, wie z.B. Rivest Cipher 4 (RC4), werden aus
            Sicherheitsgründen nicht mehr unterstützt.
          </p>
          <p>
            Dies hat zur Folge, dass ein Aufruf der Webseiten und Webservices mit veralteten
            Betriebssystemen und Webbrowsern, wie z.B. Windows XP und Internet Explorer 8, nicht
            mehr möglich ist, da diese nicht mehr den aktuellen Sicherheitsstandards entsprechen.
          </p>

          <h5>Nutzungsbestimmungen</h5>
          <p>
            Vervielfältigungen (auch auf elektronische Weise) dieses Internet-Angebotes oder von
            Teilen desselben und deren Weitergabe sind gestattet, soweit für die Vervielfältigung
            selbst bzw. für die Weitergabe kein Entgelt oder keine sonstige Gegenleistung verlangt
            wird. Die Zurverfügungstellung von Inhalten der BKA-Website auf anderen Websites ist
            nicht gestattet.
          </p>
          <h5>Barrierefreiheit/Barrierearmut</h5>
          <p>
            <a href="#footer" onClick={() => this.props.openAccessibilityModal()}>
              Zur Barrierefreiheitserklärung
            </a>
          </p>

          <h4>Design, Implementierung und Betrieb</h4>
          <p>IMENDO GmbH</p>

          <h4>Technische Umsetzung und Betreuung</h4>
          <p>IMENDO GmbH und Land-, forst- und wasserwirtschaftliches Rechenzentrum GmbH</p>

          <h4>Kontakt</h4>

          <p>
            <a
              href="mailto:support@reportingplattform.gv.at"
              title="E-Mailadresse der Reporting Plattform"
            >
              support@reportingplattform.gv.at
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Imprint;
