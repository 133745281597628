import React, { Component } from "react";
import { ArrowRight, ArrowLeft } from "../helpers/svgs";
import debounce from "lodash.debounce";
import ProjectDetails from "./ProjectDetailsPage/ProjectDetails";
import ProjectModal from "./ProjectDetailsPage/ProjectModal";
import { projects } from "../helpers/projects_texts";

const root = document.getElementById("root");

class Projects extends Component {
  projectCardsWrapper = React.createRef();

  state = {
    offset: 0,
    selectedProject: 0,
    showProjectModal: false,
  };

  closeProjectModal = () => {
    this.setState({ showProjectModal: false });
    root.classList.remove("modal-open");
    document.body.classList.remove("modal-open");
  };

  showProjectModal = (projectId) => {
    this.setState({ showProjectModal: true, selectedProject: projectId });

    root.classList.add("modal-open");
    document.body.classList.add("modal-open");
  };

  cardWidth = 500;
  cardWidthXS = 380;

  getRelevantCardWidth = () => {
    if (window.innerWidth > 576) {
      return this.cardWidth / 1.0;
    } else return this.cardWidthXS / 1.0;
  };

  showPreviousBtn = () => {
    return this.state.offset >= this.getRelevantCardWidth();
  };

  showNextBtn = () => {
    return this.state.offset < this.getMaxOffset() - 10;
  };

  getMaxOffset = () => {
    if (this.projectCardsWrapper.current) {
      var clientWidth = this.projectCardsWrapper.current.clientWidth;
      var scrollWidth = this.projectCardsWrapper.current.scrollWidth;
      var scrollMax = scrollWidth - clientWidth;
      return scrollMax;
    } else return 0;
  };

  setOffset = (offset) => {
    var offsetNew = this.state.offset + offset;
    var maxOffset = this.getMaxOffset();

    if (offsetNew < this.getRelevantCardWidth()) {
      offsetNew = 0;
    } else if (offsetNew + this.getRelevantCardWidth() > maxOffset) {
      offsetNew = maxOffset;
    }

    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    //check if IE
    if (isIE) {
      this.projectCardsWrapper.current.scrollLeft = offsetNew;
      this.setState({ offset: offsetNew });
    } else {
      this.projectCardsWrapper.current.scrollTo({
        left: offsetNew,
        behavior: "smooth",
      });
    }
  };

  onScroll = (e) => {
    var offsetNew = this.projectCardsWrapper.current.scrollLeft;

    this.setState({ offset: offsetNew });
  };

  componentDidMount() {
    if (/*@cc_on!@*/ false || !!document.documentMode)
      //check if browser is IE
      window.addEventListener("scroll", debounce(this.onScroll, 30));
    else window.addEventListener("scroll", this.onScroll, 10);
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.onScroll);
  };

  render() {
    let projectCards = projects.map((item) => (
      <div
        key={item.id}
        className="project-card"
        onClick={() => this.showProjectModal(item.id)}
      >
        <img src={item.logo} alt={"Logo " + item.department} />
        <h3>{item.title}</h3>
        <p>{item.description}</p>

        <div className="more-btn-wrapper">
          <button
            className="read-more-btn"
            onClick={() => this.showProjectModal(item.id)}
          >
            <span className="weiter">Weiter</span>{" "}
            <span className="zur">zur</span> Projektbeschreibung {ArrowRight}
          </button>
        </div>
      </div>
    ));
    return (
      <React.Fragment>
        <div
          id="section-projekte"
          ref={this.props.refName}
          className="section projects"
        >
          <div className="section-projects-content">
            <h2>Projekte</h2>
            <div className="description-wrapper">
              <div className="accent-line" />
              <div className="description">
                <div>
                  <p>
                    Im Folgenden können Sie sich durch die aktuell in der
                    Reporting Plattform vorhandenen Applikationen klicken. Für
                    das Bundesministerium für Finanzen, das Bundesministerium
                    für Justiz und das Bundesministerium für Finanzen ist der
                    zentrale Einstieg zu den jeweiligen Applikationen über die
                    Reporting Plattform tagtäglich im Einsatz.
                  </p>
                  <p>
                    In der Abbildung rechts sehen Sie aktuelle Zahlen über alle
                    Applikationen, Dashboards und Benutzer, die mit den
                    Anwendungen der Reporting Plattform tagtäglich arbeiten.
                    Klicken Sie unten auf "Aktuelle Zahlen", um auf die
                    Vollbildansicht des Berichts zu gelangen. In diesem können
                    Sie durch Klicken auf ein Ressort/Ministerium (linke
                    Maustaste + Strg = Mehrfachauswahl) die Ansicht entsprechend
                    filtern.
                  </p>{" "}
                  <div className="btn-wrapper">
                    <a
                      className="cyan-btn power-bi-demo-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://app.powerbi.com/view?r=eyJrIjoiZThmMzUxOTQtMDU3NC00YzE2LWEwYzctN2JkOWRiZDk5MmM5IiwidCI6IjczYTMxYjAxLTc2NjctNDAyNC05NWNhLTZjN2E0ZjdjNDk5OCIsImMiOjl9"
                    >
                      Aktuelle Zahlen
                    </a>
                  </div>
                </div>

                <iframe
                  title="Power-BI Dashboard mit aktuellen Zahlen zur Reporting Plattform"
                  className="power-bi-iframe"
                  src="https://app.powerbi.com/view?r=eyJrIjoiZThmMzUxOTQtMDU3NC00YzE2LWEwYzctN2JkOWRiZDk5MmM5IiwidCI6IjczYTMxYjAxLTc2NjctNDAyNC05NWNhLTZjN2E0ZjdjNDk5OCIsImMiOjl9"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="projects-lower-section">
          <div className="project-cards-wrapper" ref={this.projectCardsWrapper}>
            {projectCards}
            <div className="cards-wrapper-padding" />
          </div>
          <div className="navigate-btn-wrapper">
            <button
              className={
                "navigate-btn" + (this.showPreviousBtn() ? "" : " hide")
              }
              onClick={() => this.setOffset(-this.getRelevantCardWidth())}
            >
              {ArrowLeft} Zurück
            </button>

            <button
              className={"navigate-btn" + (this.showNextBtn() ? "" : " hide")}
              onClick={() => this.setOffset(this.getRelevantCardWidth())}
            >
              Weiter {ArrowRight}
            </button>
          </div>
        </div>
        <ProjectModal>
          <ProjectDetails
            visible={this.state.showProjectModal}
            closeProjectModal={this.closeProjectModal}
            project={projects.find(
              (item) => item.id === this.state.selectedProject
            )}
          />
        </ProjectModal>
      </React.Fragment>
    );
  }
}

export default Projects;
