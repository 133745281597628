import React, { Component } from 'react';
import { PlatformBefore, PlatformNow } from '../helpers/svgs';
import { FadeInSection } from '../helpers/FadeInSection';

class ReportingPlatform extends Component {
  state = {
    platform1Visible: false,
    platform2Visible: false,
  };

  render() {
    return (
      <div id="section-reporting-plattform" ref={this.props.refName}>
        <div className={'section platform1'}>
          <FadeInSection>
            <div className={'section-platform1-content'}>
              <h2>Bisheriges Vorgehen</h2>
              <div className="description-wrapper">
                <div className="accent-line" />
                <div className="description">
                  <h3>Die "alte" Normalität</h3>
                  <p>
                    Bisher war/ist es üblich, dass es viele isolierte Systeme
                    mit eigenständigen Berichtsstrukturen gab/gibt. Dies
                    führt(e) dazu, dass Berichtsempfänger:innen 
                    Informationen in einem davor etablierten
                    Prozess in unterschiedlichsten Formen vorgelegt bekommen,
                    zumeist papiergetrieben bzw. in Ablageverzeichnissen und
                    E-Mail. Der Hintergrund: Berichtsempfänger:innen 
                    steigen zu einem großen Teil nicht
                    selbstständig in unterschiedliche Anwendungen ein. Die
                    Reporting Plattform liefert die unterschiedlichsten
                    Informationen an einem zentralen Punkt. Die
                    Berichtsempfänger:innen können dadurch
                    örtlich und zeitlich unabhängig die bereitgestellten
                    Informationen auf allen Endgeräten konsumieren. Natürlich
                    auch auf mobilen Endgeräten.
                  </p>
                </div>
              </div>
            </div>
          </FadeInSection>
          <div className={'platform-before'}>
            <FadeInSection>{PlatformBefore}</FadeInSection>
          </div>
        </div>

        <div className="section platform2" ref={this.platform2}>
          <div className={'platform-now'}>
            <FadeInSection>{PlatformNow}</FadeInSection>
          </div>
          <FadeInSection>
            <div className={'section-platform2-content'}>
              <h2>So funktioniert's</h2>
              <div className="description-wrapper">
                <div className="accent-line" />
                <div className="description">
                  <h3>Vom Einzelkämpfer zum “Gemeinsam sind wir stark” </h3>
                  <p>
                    Die Plattform bietet vordefinierte Funktionalitäten (sog. Shared Services), die von allen
                    Applikationen in der Plattform verwendet werden können, aber
                    nicht müssen. Ihre Anwendung profitiert von den neusten
                    bereitgestellten Anbindungen, die durch Pen-Tests und
                    regelmäßige Security Audits überprüft werden. Das Rad muss
                    nicht neu erfunden werden und Ihre Applikation ist immer auf
                    dem neuesten Stand.
                  </p>
                  <h4>Wir bieten folgende vordefinierte Services:</h4>
                  <ul>
                    <li>Authentifizierung (PVP, SAML, OpenID-Connect)</li>
                    <li>Timerjobs (Bereitstellung für zyklische Aufgaben)</li>
                    <li>Import / Export Schnittstellen (CSV, REST, ODATA)</li>
                    <li>
                      Zebra BI Bibliotheken nach dem International Business
                      Communication Standards (IBCS®)
                    </li>
                    <li>Volltextsuche</li>
                    <li>Audit-Logging</li>
                    <li>Datenablage (Koppelung an eOffice/SharePoint)</li>
                    <li>Digitale Hilfe und Benutzerhandbuch inkl. Chatbot</li>
                    <li>Reporting-Stack (Power BI)</li>
                    <li>Workflow Engine</li>
                    <li>Notification Center</li>
                    <li>Machine Learning</li>
                    <li>Predictive Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
          </FadeInSection>
        </div>
      </div>
    );
  }
}

export default ReportingPlatform;
