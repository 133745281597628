import React, { Component } from 'react';
import debounce from 'lodash.debounce';

import { FadeInSection } from '../helpers/FadeInSection';
import {
  HandHoldingBox,
  Code,
  Sitemap,
  THLarge,
  PowerBI,
  DrawCircle,
  PaintBrush,
  Mobile,
  AtomLight,
  Analytics,
} from '../helpers/svgs';

const cards = [
  {
    id: 0,
    icon: DrawCircle,
    title: 'App-2-App Kommunikation',
    description:
      'Falls es der Anlassfall verlangt, ist trotz isolierter Speicherbereiche eine gewollte Kommunikation unter den Applikationen möglich. Hierzu kommen wohl definierte REST-Schnittstellen (Representational State Transform) zum Einsatz, um gewollte Daten untereinander austauschen bzw. bereitstellen zu können.',
  },
  {
    id: 1,
    icon: Mobile,
    title: 'Alle Informationen immer zur Hand',
    description:
      'Egal ob Sie vom Büro aus arbeiten oder einen schnellen Zugriff zu Ihren Daten von unterwegs benötigen: Die Reporting Plattform begleitet Sie durch Ihren Arbeitsalltag und unterstützt Sie z.B. auch mobil am Tablet.',
  },
  {
    id: 7,
    icon: AtomLight,
    title:
      'Ankoppelung bzw. Nutzung von Machine-Learning-Techniken auf den Datenbestand',
    description: '',
  },
  {
    id: 6,
    icon: Sitemap,
    title: 'Ressortübergreifende Bereitstellung',
    description:
      'Der Betrieb aller reporting- und controllingnahen Applikationen (ressortübergreifend) wird über eine einheitliche IT-Plattform bereitgestellt. Der Einstieg zur jeweiligen Applikation erfolgt mittels PVP Login. Die Berechtigungsvergabe bleibt weiterhin “in-house” im zentralen Berechtigungscenter (= Kompass-System) und kann bei Bedarf in der Applikationen noch um weitere Rollenberechtigungen entsprechend erweitert werden.',
  },
  {
    id: 8,
    icon: PaintBrush,
    title:
      'Einheitliches GUI Layout mit Web Accessibility Initiative (WAI) Konformität',
    description:
      'Bei der Umsetzung der Applikation wurde spezielles Augenmerk auf die grafische Oberfläche und übersichtliche Datenaufbereitung gelegt. Die WAI Konformität ist ein zentraler Punkt bei den Produktivsetzungen und wird entsprechend umgesetzt.',
  },
  {
    id: 5,
    icon: PowerBI,
    title: 'Power BI',
    description:
      'Sie benötigen eine Reporting-Engine. Hierzu bietet Ihnen die Plattform den zentralen Service – Power BI an. Mittels Power BI erhalten Sie eine grafische Darstellung aller Daten in Ihrer Applikation in einem schlicht-modernen und benutzerfreundlichen Design.',
  },
  {
    id: 3,
    icon: Code,
    title: 'Unterschiedliche Programmiersprachen und Technologien',
    description:
      'Sowohl die Entwicklung als auch der Betrieb der Plattform sind unabhängig von den dabei verwendeten Technologien (ASP.Net Core, Node.js, usw.) und Programmiersprachen (Java, C# usw.)',
  },

  {
    id: 2,
    icon: HandHoldingBox,
    title: 'Vordefinierte Services',
    description:
      'Jede in der Reporting Plattform befindliche Applikation kann an die vordefinierten Services angebunden werden und davon profitieren. Ganz nach dem Motto - “Erfinden Sie das Rad nicht neu, sondern nutzen Sie die Vorzüge”!',
  },

  {
    id: 16,
    icon: THLarge,
    title: 'Isolierter Betrieb der Applikationen',
    description:
      'Die Daten der Applikation werden nicht in einem Shared-Environment gespeichert, sondern besitzen eine dezidierte Datenbank bzw. einen abgekapselten Speicherbereich.',
  },
  {
    id: 9,
    icon: Analytics,
    title: '(Markt)Forschung',
    description:
      'Analyse- und BI-Plattformen inkl. erweiterter Funktionalitäten werden heutzutage immer bedeutender. Durch die Marktforschung unabhängiger Institutionen werden Unternehmen mittels vordefinierter Kriterien gemessen und das Ergebnis grafisch z.B. als Magic Quadrant dargestellt. Dieser Magic Quadrant (englisch) soll dabei helfen Daten- und Analytikunternehmen ihr Technologie-Portfolio in die richtige Richtung weiterzuentwickeln.',
  },
];

class Benefits extends Component {
  state = {
    visible: false,
    visibleBenefit1: false,
  };

  benefit1 = React.createRef();

  onScroll = () => {
    if (
      document.documentElement.scrollTop + window.innerHeight / 2 >=
      this.props.refName.current.offsetTop
    ) {
      this.setState({ visible: true });
    }
  };

  componentDidMount() {
    if (/*@cc_on!@*/ false || !!document.documentMode)
      //check if browser is IE
      window.addEventListener('scroll', debounce(this.onScroll, 30));
    else window.addEventListener('scroll', this.onScroll, 10);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  };

  render() {
    var tCards = cards.map((item, index) => (
      <FadeInSection key={item.id}>
        <div className={'technology-card card-' + ((item.id % 4) + 1)}>
          {item.icon}
          <h3>{item.title}</h3>

          {item.id === 9 ? (
            <>
              <p>
                Analyse- und BI-Plattformen inkl. erweiterter Funktionalitäten
                werden heutzutage immer bedeutender. Durch die Marktforschung
                unabhängiger Institutionen werden Unternehmen mittels
                vordefinierter Kriterien gemessen und das Ergebnis grafisch z.B.
                als Magic Quadrant dargestellt. Dieser{' '}
                <a href="https://www.gartner.com/en/research/methodologies/magic-quadrants-research">
                  Magic Quadrant (englisch)
                </a>{' '}
                soll dabei helfen Daten- und Analytikunternehmen ihr
                Technologie-Portfolio in die richtige Richtung
                weiterzuentwickeln.
              </p>{' '}
              <p>
                Die aktuellen Forschungsergebnisse können Sie{' '}
                <a href="https://www.gartner.com/en/documents/3980852/magic-quadrant-for-analytics-and-business-intelligence-p">
                  hier
                </a>{' '}
                nachlesen.
              </p>
            </>
          ) : (
            <p>{item.description}</p>
          )}
        </div>
      </FadeInSection>
    ));

    return (
      <div id="section-vorteile" className="benefits" ref={this.props.refName}>
        <div className="section benefit1">
          <div className="section-benefit1-content">
            <h2>Mehrwert der Plattform</h2>
            <div className="description-wrapper">
              <div className="accent-line" />
              <div className="description"></div>
            </div>
          </div>
        </div>

        <div className="technology-cards-wrapper">
          <div className={'technology-cards-1'}>
            {window.innerWidth < 1200 ? (
              <>{tCards}</>
            ) : window.innerWidth < 1600 ? (
              <>{tCards.slice(0, 3)}</>
            ) : (
              <>{tCards.slice(0, 3)}</>
            )}
          </div>
          <div className={'technology-cards-2'}>
            {window.innerWidth < 1200 ? null : window.innerWidth < 1600 ? (
              <>{tCards.slice(3, 6)}</>
            ) : (
              <>{tCards.slice(3, 5)}</>
            )}
          </div>
          <div className={'technology-cards-3'}>
            {window.innerWidth < 1200 ? null : window.innerWidth < 1600 ? (
              <>{tCards.slice(6, 10)}</>
            ) : (
              <>{tCards.slice(5, 8)}</>
            )}
          </div>
          <div className={'technology-cards-4'}>
            {window.innerWidth < 1200 ? null : window.innerWidth <
              1600 ? null : (
              <>{tCards.slice(8, 10)}</>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Benefits;
