import React, { Component } from 'react';
import { FadeInSection } from '../helpers/FadeInSection';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// import * as leaflet from "leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import marker from '../images/marker.png';

class Contact extends Component {
  render() {
    return (
      <div id="section-kontakt" ref={this.props.refName} className="section contact">
        <div className="map-container">
          <MapContainer
            center={[48.20858008586092, 16.364081775858857]}
            zoom={17.2}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='Datenquelle: <a href="https://www.basemap.at">basemap.at</a>'
              url="https://maps{s}.wien.gv.at/basemap/bmaphidpi/normal/google3857/{z}/{y}/{x}.jpeg"
              type="normal"
              format="png"
              style={{ width: 400, height: 400 }}
              subdomains={['', '1', '2', '3', '4']}
              maxZoom={20}
              minZoom={9}
            />
            <Marker
              icon={L.icon({ iconUrl: marker, iconAnchor: [0, 48], popupAnchor: [16, 0], iconSize: [32, 48] })}
              position={[48.20858008586092, 16.364081775858857]}
            >
              <Popup>
                Bundeskanzleramt Österreich <br /> Ballhausplatz 2, 1010 Wien
              </Popup>
            </Marker>
          </MapContainer>
        </div>

        <FadeInSection>
          <div className="section-contact-content">
            <h2>Kontaktieren Sie uns!</h2>
            <div className="description-wrapper">
              <div className="accent-line" />
              <div className="description">
                <p>
                  Sind Sie nun neugierig geworden und könnten Sie sich einen oder vielleicht auch
                  mehrere Ihrer Anwendungsfälle als Teil der Reporting Plattform vorstellen?
                </p>
                <p>
                  Gerne können Sie uns unter nachfolgenden Kontaktdaten für ein Erstgespräch
                  erreichen.
                </p>

                <div className="contact-info-wrapper">
                  <div className="contact-info-item">
                    <div className="contact-heading">Bundeskanzleramt Österreich</div>
                    <div>Sektion VII: Digitalisierung und E-Government</div>
                    <div>Abteilung VII/1: Digitale Strategien und Innovationen</div>
                    <div>Abteilung VII/3: Informationsmanagement und Services</div>
                  </div>
                  <div className="contact-info-item">
                    <div>MinRat Mag.(FH) Siegfried Kabasser</div>
                    <div>
                      <a
                        href="mailto:siegfried.kabasser@bka.gv.at"
                        title="E-Mail Adresse Reportingplattform"
                      >
                        siegfried.kabasser@bka.gv.at
                      </a>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div>Ballhausplatz 2, 1010 Wien, Österreich </div>
                    <div>
                      { /*
                      <a
                        href="https://www.facebook.com/finanzministerium"
                        title="Facebook Seite des BMF"
                      >
                        https://www.facebook.com/finanzministerium
                    
                      </a>
                      */ }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default Contact;
