import React, { Component } from 'react';
import { Times } from '../../helpers/svgs';

class Accessibility extends Component {
  render() {
    return (
      <div className={'imprint' + (this.props.visible ? '' : ' hide')} role="dialog">
        <div className="imprint-card">
          <button
            className="close-image-modal-btn"
            onClick={this.props.closeImageModal}
            data-dismiss="modal"
            aria-label="Schließen"
          >
            {Times}
          </button>
          <h2>Barrierefreiheitserklärung</h2>
          <h4>Erklärung zur Barrierefreiheit</h4>
          <p>
            Das Bundeskanzleramt Österreich ist bemüht, seine Websites im Einklang mit dem{' '}
            <a
              href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20010727"
              target="_blank" rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              Web-Zugänglichkeits-Gesetz (WZG)
              <abbr lang="de" title="in der geltenden Fassung">
                {' '}
                idgF
              </abbr>
            </a>{' '}
            zur Umsetzung der{' '}
            <a
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016L2102"
              target="_blank" rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              Richtlinie (EU) 2016/2102 des Europäischen Parlaments und des Rates vom 26. Oktober
              2016 über den barrierefreien Zugang zu den Websites und mobilen Anwendungen
              öffentlicher Stellen
            </a>{' '}
            (Amtsblatt L 327 vom 2.12.2016, S. 1) barrierefrei zugänglich zu machen.
          </p>

          <p>
            Diese Erklärung zur Barrierefreiheit gilt für die Website{' '}
            <a href="https://www.reportingplattform.gv.at/">www.reportingplattform.gv.at</a>.
          </p>

          <h4>Stand der Vereinbarkeit mit den Anforderungen</h4>
          <p>
            Diese Website ist wegen der folgenden Unvereinbarkeiten und Ausnahmen teilweise mit{' '}
            <strong>
              Konformitätsstufe AA der "Richtlinien für barrierefreie Webinhalte Web -{' '}
            </strong>
            <strong>
              <a
                href="https://www.w3.org/TR/WCAG21/"
                target="_blank" rel="noopener noreferrer"
                title="Öffnet in einem neuen Fenster"
              >
                WCAG 2.1
              </a>
              "
            </strong>{' '}
             beziehungsweise mit dem geltenden Europäischen Standard EN 301 549 V2.1.2
            (2018-08) vereinbar.
          </p>

          <h4>Nicht barrierefreie Inhalte</h4>

          <p>
            Die nachstehend aufgeführten Inhalte sind aus den folgenden Gründen nicht barrierefrei:
          </p>

          <h5>a) Unvereinbarkeit mit den Barrierefreiheitsbestimmungen</h5>
{/*  */}
            <p>
              Videos und Animationen, die auf der Website eingebettet sind,
              verfügen über keine akustische Bildbeschreibung.
            </p>
            <p>
              Teilweise sind visuell als Liste erscheinende Inhalte nicht als
              solche ausgezeichnet.
            </p>
            <p>
              Links heben sich auf einzelnen Stellen nicht ausreichend vom
              übrigen Text ab.
            </p>
            <p>
              Auf einzelnen Seiten sind angezeigte Kacheln nicht mit der
              Tastatur steuerbar. Sie können zwar fokussiert, aber nicht
              aktiviert werden.
            </p>
            <p>
              Beim Öffnen der Projekt-Detailansicht fehlt eine alternative
              Navigations-Möglichkeit.
            </p>
            <p>
              Der über iFrame eingebettete PowerBI bericht ist nicht mit der
              Tastatur-navigierbar und verfügt über keine alternative
              Daten-Beschreibung.
            </p>


          <h5>b) Unverhältnismäßige Belastung</h5>

          <p>
            Unsere Videos sind gehostet und veröffentlicht in der Video-Plattform{' '}
            <span dir="ltr" lang="en">
              Youtube
            </span>
            . Es ist nicht möglich, für einige dieser Videos die geforderten Audiobeschreibungen zur
            Verfügung zu stellen. Damit ist das{' '}
            <abbr lang="en" title="Richtlinien für barrierefreie Webinhalte">
              WCAG
            </abbr>
            -Erfolgskriterium 1.2.5 (Audiodeskription aufgezeichnet) nicht erfüllt. Wir sind der
            Ansicht, dass eine Behebung eine unverhältnismäßige Belastung im Sinne der
            Barrierefreiheitsbestimmungen darstellen würde.
          </p>

          <h4>Erstellung dieser Erklärung zur Barrierefreiheit</h4>

          <p>
            Die Bewertung der Vereinbarkeit der Website mit dem{' '}
            <abbr lang="de" title="Web-Zugänglichkeits-Gesetz">
              WZG
            </abbr>{' '}
            zur Umsetzung der Anforderungen der Richtlinie (
            <abbr lang="de" title="Europäische Union">
              EU
            </abbr>
            ) 2016/2102 erfolgte in Form eines internen{' '}
            <span dir="ltr" lang="en">
              Accessibility
            </span>{' '}
            Audits nach{' '}
            <abbr lang="en" title="Richtlinien für barrierefreie Webinhalte">
              WCAG
            </abbr>{' '}
            2.1 Konformitätslevel AA im Dezember 2022. 
          </p>
          <p>Diese Erklärung wurde zuletzt am 01. März 2023 aktualisiert.</p>

          <h4>
            <span dir="ltr" lang="en">
              Feedback
            </span>{' '}
            und Kontaktangaben
          </h4>

          <p>
            Die Angebote und Services auf dieser{' '}
            <span dir="ltr" lang="en">
              Website
            </span>{' '}
            werden laufend verbessert, ausgetauscht und ausgebaut. Dabei ist uns die Bedienbarkeit
            und Zugänglichkeit ein großes Anliegen.
          </p>

          <p>
            Wenn Ihnen Barrieren auffallen, die Sie an der Benutzung unserer{' '}
            <span dir="ltr" lang="en">
              Website
            </span>{' '}
            behindern – Probleme, die in dieser Erklärung nicht beschrieben sind, Mängel in Bezug
            auf die Einhaltung der Barrierefreiheitsanforderungen – so bitten wir Sie, uns diese per
            E‑Mail mitzuteilen.
            <br />
            Wir werden Ihre Anfrage prüfen und Sie ehestmöglich kontaktieren.
          </p>

          <p>
            Sämtliche Mitteilungen und Anregungen senden Sie uns bitte an {' '}
            <a href="mailto:post.praes-1@bmf.gv.at" target="_blank" rel="noopener noreferrer">
              post.praes-1@bmf.gv.at
            </a>{' '}
            mit dem Betreff "Meldung einer Barriere in der{' '}
            <span dir="ltr" lang="en">
              Website
            </span>{' '}
            reportingplattform.gv.at". Bitte beschreiben Sie das Problem und führen Sie uns die{' '}
            <abbr lang="en" title="Uniform Resource Locator">
              URL
            </abbr>
            (s) der betroffenen Webseite oder des Dokuments an.
          </p>

          <p>
            Kontakt:
            <br />
            Bundeskanzleramt Österreich
            <br />
            Medien &amp; Barrierefreiheit
            <br />
            E-Mail:{' '}
            <a href="mailto:barrierefreiheit@bka.gv.at" target="_blank" rel="noopener noreferrer">
            barrierefreiheit@bka.gv.at
            </a>
          </p>

          <h4>Durchsetzungsverfahren</h4>

          <p>
            Bei nicht zufriedenstellenden Antworten aus oben genannter Kontaktmöglichkeit können Sie
            sich mittels Beschwerde an die Österreichische Forschungsförderungsgesellschaft mit
            beschränkter Haftung (FFG) wenden. Die{' '}
            <abbr lang="de" title="Österreichische Forschungsförderungsgesellschaft mbH">
              FFG
            </abbr>{' '}
            nimmt über das Kontaktformular Beschwerden auf elektronischem Weg entgegen.
          </p>

          <p>
            <a
              href="https://www.ffg.at/form/kontaktformular-beschwerdestelle"
              target="_blank" rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              Kontaktformular der Beschwerdestelle
            </a>
          </p>

          <p>
            Die Beschwerden werden von der{' '}
            <abbr lang="de" title="Österreichische Forschungsförderungsgesellschaft mbH">
              FFG
            </abbr>{' '}
            dahingehend geprüft, ob sie sich auf Verstöße gegen die Vorgaben des
            Web-Zugänglichkeits-Gesetzes, insbesondere Mängel bei der Einhaltung der
            Barrierefreiheitsanforderungen, durch den Bund oder einer ihm zuordenbaren Einrichtung
            beziehen.
          </p>

          <p>
            Sofern die Beschwerde berechtigt ist, hat die{' '}
            <abbr lang="de" title="Österreichische Forschungsförderungsgesellschaft mbH">
              FFG
            </abbr>{' '}
            dem Bund oder den betroffenen Rechtsträgern Handlungsempfehlungen auszusprechen und
            Maßnahmen vorzuschlagen, die der Beseitigung der vorliegenden Mängel dienen.
          </p>

          <p>
            <a
              href="https://www.ffg.at/barrierefreiheit/beschwerdestelle"
              target="_blank"  rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              Weitere Informationen zum Beschwerdeverfahren
            </a>
          </p>

          <h4>Fakultative Inhalte: Zusatzinformationen und Bedienungshilfe</h4>

          <p>
            Das Internet-Team des Bundesministeriums für Finanzen ist um eine möglichst
            barrierefreie Zugänglichkeit zu seinen Internet-Angeboten bemüht. Dabei
            orientieren wir uns seit dem Jahr 2008 in Übereinstimmung mit den nationalen
            Rechtsvorschriften "E-Government -Gesetz 2004" und den geltenden einschlägigen
            Anti-Diskriminierungsbestimmungen an den Richtlinien für barrierefreie Inhalte{' '}
            <abbr lang="en" title="Richtlinien für barrierefreie Webinhalte">
              WCAG
            </abbr>{' '}
            2.0.
          </p>

          <p>
            Informationen zur Benutzung finden Sie in der{' '}
            <a href="https://www.bmf.gv.at/public/barrierefreiheitserklaerung/hilfe-barrierefreiheit.html">
              Hilfe zur Barrierefreiheit
            </a>
            .
          </p>
          {/* <h2>Informationen zur Barrierefreiheit</h2>
          <h4>
            Die Barrierefreiheit ermöglicht allen Menschen – mit und ohne
            Behinderung – die uneingeschränkte Nutzung von Dienstleistungen,
            Einrichtungen und Gegenständen im täglichen Leben.
          </h4>
          <h3>Nicht barrierefreie Inhalte</h3>
          <p>
            Die nachstehend aufgeführten Inhalte sind aus den folgenden Gründen
            nicht barrierefrei:
          </p>
          <ul>
            <li>
              Videos und Animationen, die auf der Website eingebettet sind,
              verfügen über keine akustische Bildbeschreibung.
            </li>
            <li>
              Teilweise sind visuell als Liste erscheinende Inhalte nicht als
              solche ausgezeichnet.
            </li>
            <li>
              Links heben sich auf einzelnen Stellen nicht ausreichend vom
              übrigen Text ab.
            </li>
            <li>
              Auf einzelnen Seiten sind angezeigte Kacheln nicht mit der
              Tastatur steuerbar. Sie können zwar fokussiert, aber nicht
              aktiviert werden.
            </li>
            <li>
              Beim Öffnen der Projekt-Detailansicht fehlt eine alternative
              Navigations-Möglichkeit.
            </li>
            <li>
              Der über iFrame eingebettete PowerBI bericht ist nicht mit der
              Tastatur-navigierbar und verfügt über keine alternative
              Daten-Beschreibung.
            </li>
          </ul>

          <h3>Service</h3>
          <p>
            Sollten Sie nicht-barrierefreie Inhalte in einer für Sie
            zugänglichen Form benötigen, wenden Sie sich bitte per E-Mail an{" "}
            <a
              href="mailto:support@reportingplattform.gv.at"
              title="E-Mailadresse der Reporting Plattform"
            >
              support@reportingplattform.gv.at
            </a>
            . Wir werden versuchen, Ihnen die Inhalte so weit wie möglich in
            geeigneter Art und Weise zur Verfügung zu stellen.
          </p>
          <h3>Feedback und Kontaktangaben</h3>
          <p>
            Sofern Sie Mängel in Bezug auf die Einhaltung der
            Barrierefreiheitsanforderungen feststellen oder Sie Informationen
            über etwaige von der Barrierefreiheit ausgenommenen Inhalte einholen
            möchten – so wenden Sie sich bitten per E-Mail an:
          </p>
          <div>Reportingplattform des Bundes</div>
          <a
            href="mailto:support@reportingplattform.gv.at"
            title="E-Mailadresse der Reporting Plattform"
          >
            support@reportingplattform.gv.at
          </a> */}
        </div>
      </div>
    );
  }
}

export default Accessibility;
