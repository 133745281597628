import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

class Roadmap extends Component {
  render() {
    return (
      <div 
        id="history"
        className="d-none"
      >

        <div 
            className="timeline-wrapper" 
        >
          <VerticalTimeline className="timeline">
            <VerticalTimelineElement
              dateClassName="timeline-date date-long"
              date="2022"
            />
            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Nutzung der Reportingplattform auf mobilen
                Endgeräten
              </h3>
              <p className="timeline-date-right">Ende Q4 2022</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Implementierung eines neuen Workflows, damit
                Benutzerberechtigungen schneller bzw. bis zu einem gewissen Grad
                automatisiert durchgeführt werden können und Anbindung an
                „Serviceportal Bund“
              </h3>
              <p className="timeline-date-right">Ende Q4 2022</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Umsetzung der Open Shift Technologie für neue Anwendungsfälle
              </h3>
              <p className="timeline-date-right">Ende Q4 2022</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Auf- und Ausbau des Selfservice-BI Ansatzes
              </h3>
              <p className="timeline-date-right">Ende Q3 2022</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Aufbau eines Betriebsdashboards zu Verfügbarkeiten der
                Reportingplattform und zur Darstellung der Kostenverteilung
              </h3>
              <p className="timeline-date-right">Ende Q2 2022</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              date="2021"
            />

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Umsetzung bzw. Anbindung der Reporting Plattform für
                ausgegliederte Rechtsträge mittels Active Directory Federation
                Services (kurz: ADFS, ohne Portalanbindung)
              </h3>
              <p className="timeline-date-right">Q4 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                DSGVO Abfragemodul als Shared Service
              </h3>
              <br />
              <h3 className="vertical-timeline-element-title">
                Einbinden des neuen Designs der Reporting Plattform sowie aller
                Notification Funktionalitäten
              </h3>
              <p className="timeline-date-right">Q4 2021</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Proof of Concept (PoC) zur Anbindung via e-ID
              </h3>
              <p className="timeline-date-right">Q3 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Erweiterung der Mobile BI Funktionalitäten
              </h3>
              <p className="timeline-date-right">Q2 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Konzept des Redesigns der gesamten Plattform sowie aller
                Notification Funktionalitäten
              </h3>
              <p className="timeline-date-right">Q2 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Stetige Erweiterung der bestehenden Fachanwendungen (wie
                beispielsweise BMFDashboard, CDOMonitoring, etc.)
              </h3>
              <p className="timeline-date-right">Q1 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Laufende Umsetzungen von individuellen Anwendungsfällen der
                Fachbereiche in unterschiedlichen Organisationen. (
                BMAWNewslettertool, ÖBAGControllingdatenbank, etc)
              </h3>
              <p className="timeline-date-right">Q1 2021</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              date="2020"
            />

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Automatische zeitliche Skalierung der PowerBI Cloud
                Infrastruktur
              </h3>
              <p className="timeline-date-right">Q4 2020</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Shared Service zur Anbindung von Daten der Statistik Austria
              </h3>
              <p className="timeline-date-right">Q4 2020</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Konzepterstellung der Anbindung der Reporting Plattform für
                ausgegliederte Rechtsträge mittels Active Directory Federation
                Services (kurz: ADFS, ohne Portalanbindung)
              </h3>
              <p className="timeline-date-right">Q3 2020</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Ausbau der Shared Services
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Bereitstellung der Reporting Plattform in einer hochverfügbaren
                Infrastruktur (innerhalb der BRZ GmbH)
              </h3>
              <p className="timeline-date-right">Q3 2020</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Erweiterung der bestehenden Fachanwendungen (wie beispielsweise
                Risikomanagement, Budgetdashboards, etc.)
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Umsetzungen von individuellen Anwendungsfällen der Fachbereiche
                in den jeweiligen Organisationen. Wobei hier die Organisationen
                als eigenständige Auftraggeber fungieren
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement />
          </VerticalTimeline>
        </div> 
        </div> 
    );
  }
}

export default Roadmap;
