import React, { Component } from 'react';
import { TechnologyPlatform } from '../helpers/svgs';
import { FadeInSection } from '../helpers/FadeInSection';

class Technology extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          id="section-technik"
          ref={this.props.refName}
          className="section technology"
        >
          <div className={'technology-platform'}>
            <FadeInSection>{TechnologyPlatform}</FadeInSection>
          </div>

          <FadeInSection>
            <div className="section-technology-content">
              <h2>Technischer Hintergrund</h2>
              <div className="description-wrapper">
                <div className="accent-line" />
                <div className="description">
                  <p>
                    Der gesamte Betrieb als auch die zur Verfügungstellung der
                    einzelnen Services liegt im Aufgabenbereich der Bundesrechenzentrum (BRZ) GmbH.
                    Die Daten sind vor Ort gespeichert und bekommen pro
                    Applikation einen isolierten Speicherbereich zugeordnet, um
                    eine Mandantentrennung sicherzustellen. Für alle technischen
                    Fragen benötigen Sie lediglich einen Ansprechpartner - die
                    BRZ GmbH.
                  </p>
                </div>
              </div>
            </div>
          </FadeInSection>
          <div className="technology-accent" />
        </div>
        <div className="test-demo-section-wrapper">
          <div className="test-demo-section">
            <h3>Jetzt Demo testen</h3>
            <div className="description-wrapper">
              <div className="accent-line" />
              <div className="description">
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                </p>
                <div className="test-btns-wrapper">
                  <button className="lime-btn">Test me now</button>
                  <button className="cyan-btn">Power BI Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Technology;
