import logoBMAW from '../images/logos/BMAW_Logo.png';
import logoBMF from '../images/logos/BMF_Logo_schwarz.png';

import buergerservice1 from '../images/screenshots/BMAW_Bürgerservice.jpg';
import buergerservice2 from '../images/screenshots/BMAW_Bürgerservice_app1.jpg';
import buergerservice3 from '../images/screenshots/BMAW_Bürgerservice_app2.jpg';

// import kabinettDashboard1 from '../images/screenshots/BMAW_KabinettDashboard_PBI.jpg';

import leistungsreporting1 from '../images/screenshots/BMAW_Leistungsreporting.jpg';
import leistungsreporting2 from '../images/screenshots/BMAW_Leistungsreporting_App1.jpg';
import leistungsreporting3 from '../images/screenshots/BMAW_Leistungsreporting_App2.jpg';
import leistungsreporting4 from '../images/screenshots/BMAW_Leistungsreporting_PBI.jpg';

import reportingTool1 from '../images/screenshots/BMAW_ReportingTool_Projektdetailansicht.jpg';
import reportingTool2 from '../images/screenshots/BMAW_ReportingTool_Projektübersicht.jpg';
import reportingTool3 from '../images/screenshots/BMAW_ReportingTool_Themendetailansicht.jpg';
import reportingTool4 from '../images/screenshots/BMAW_ReportingTool_Themen.jpg';

import risikoManagement1 from '../images/screenshots/Dashboard.jpg';
import risikoManagement2 from '../images/screenshots/Risiken.jpg';
import risikoManagement3 from '../images/screenshots/Massnahmen.jpg';
import risikoManagement4 from '../images/screenshots/Bewertung.jpg';
import risikoManagement5 from '../images/screenshots/Risikomatrix.png';
import risikoManagement6 from '../images/screenshots/Risikoverlauf.png';

import risikoManagementPDF1 from '../images/screenshots/Gesamtuebersicht_DEMO.pdf';
import risikoManagementPDF2 from '../images/screenshots/Risiken_DEMO.pdf';
import risikoManagementPDF3 from '../images/screenshots/Massnahmen_DEMO.pdf';

export const projects = [
  // {
  //   id: 0,
  //   logo: logoBMF,
  //  department: "BMF",
  //   title: 'Ressort Controlling',
  //   images: [ressortControlling1],
  //   description: `Die interne Controlling Plattform bietet dem Bundesministerium für Finanzen die Möglichkeit, monatlich bzw. quartalsweise Daten
  //   aus unterschiedlichen Abteilungen und Sektionen zu erfassen.`,

  //   fullText: [
  //     'Die interne Controlling Plattform bietet dem Bundesministerium für Finanzen die Möglichkeit, monatlich bzw. quartalsweise Daten aus unterschiedlichen Abteilungen und Sektionen zu erfassen. Diese Daten können entweder direkt ins System eingetragen, oder per Excel- bzw. CSV-Import bereitgestellt werden.',
  //     'Durch hinterlegte Freigabeworkflows können einzelne Berichte, dazugehörige Management Summaries (= textuelle Beschreibung der Berichtskennzahlen), bis hin zu einzelnen Kennzahlen freigegeben werden.',
  //     'Zusätzlich ist das System noch mit einem Notificationworkflow ausgestattet, in dem Erinnerungsmails zu vordefinierten Zeiten (z.B. an jeden 5. des Monats) an die jeweiligen Verantwortlichen ausgeschickt werden. So stehen für den Monats-/Quartalsbericht die Kennzahlen immer rechtzeitig zur Verfügung.',
  //     'Eine Sicherheitsabfrage sperrt die Möglichkeit der Berichtsbefüllung, wenn der entsprechende Befüllungszeitraum verstrichen ist. Lediglich Administratoren des Systems sind dazu befugt einzelne Berichte für Sachbearbeiter erneut freizuschalten. Die initiale Freischaltung eines Berichts ist im System hinterlegt und passiert somit automatisiert (entweder zu Monats- oder Quartalsbeginn).',
  //     'Das RessortControlling ist mittels PVP-Authentifizierung abgesichert. Somit ist es nur befugten Benutzern möglich in das System einzusteigen, um die sensiblen Daten zu bearbeiten. Durch die Einbindung aller berechtigten Benutzer aus der Kompass-Benutzerverwaltung, werden in der Applikation lediglich Zusatz- und App-spezifische Rollen vergeben. Die Grundberechtigung findet im Kompass statt.',
  //   ],
  //   from: 'Bundesministerium für Finanzen',
  //   services: ['Portalanbindung', 'Kompass'],
  // },
  // {
  //   id: 1,
  //   logo: logoBMF,
  //   department: "BMF",
  //   title: 'Liquiditätsdashboard',
  //   images: [],
  //   description: `Das Liquiditätsdashboard schafft für das Bundesministerium für Finanzen eine Plattform für alle Budgetflüsse von IT-Projekten.`,
  //   fullText: [
  //     'Das Liquiditätsdashboard schafft für das Bundesministerium für Finanzen eine Plattform für alle Budgetflüsse von IT-Projekten. Die Daten werden für die Jahresplanung eingegeben und aliquot auf das kommende Jahr aufgeteilt. Alle laufenden Zahlungsflüsse und Bestellungen werden mittels Excel bzw. CSV Import monatlich in die Applikation hochgeladen.',
  //     'Diese Informationen werden in einem Power BI Bericht dargestellt, der Auswertungen für Plan Cash Flow, Ist Cash Flow und Planungsdaten pro Monat und Jahr ermöglicht. Zusätzlich ist es möglich, alle Zahlungs- und Bestelldaten dort zu überprüfen.',
  //     'Das Liquiditätsdashboard ist mittels PVP-Authentifizierung abgesichert. Somit ist es nur befugten Benutzern möglich in das System einzusteigen, um die sensiblen Daten zu bearbeiten. Durch die Einbindung aller berechtigten Benutzer aus der Kompass-Benutzerverwaltung, werden in der Applikation lediglich Zusatz- und App-spezifische Rollen vergeben. Die Grundberechtigung findet im Kompass statt.',
  //   ],
  //   from: 'Bundesministerium für Finanzen',
  //   services: ['Portalanbindung', 'Power BI'],
  // },
  {
    id: 0,
    logo: logoBMAW,
    department: 'BMAW',
    title: 'Bürgerservice',
    images: [buergerservice1, buergerservice2, buergerservice3],
    description: `Die Applikation bietet dem Bürgerservice des Bundesministeriums für Finanzen eine Vielzahl an Funktionalitäten für die Unterstützung ihrer Tätigkeiten.`,
    fullText: [
      'Die Applikation bietet dem Bürgerservice des Bundesministeriums für Arbeit und Wirtschaft eine Vielzahl an Funktionalitäten für die Unterstützung ihrer Tätigkeiten. Alle Anfragen, die an den Bürgerservice gestellt werden, werden zentral über die Applikation erfasst und nach Themengebiet und Anfrageart kategorisiert.',
      'In der Applikation werden des Weiteren Lagerstände der Werbeartikel und Infomaterial, weitere FAQs für Anfragen und Textbausteine verwaltet. Zusätzlich dient die Applikation als zentrale Informationsplattform für die Mitarbeiter/innen des Bürgerservice.',
      'Alle Anfragen werden dann in einem Power BI Bericht ausgewertet. Einzelne Kennzahlen für Anfragen pro Monat/KW, Anfragen pro Themenbereich und Anfrageart können darüber abgerufen werden.',
    ],
    from: 'Bundesministerium für Arbeit und Wirtschaft',
    services: ['Suche', 'Portalanbindung', 'Power BI'],
  },
  {
    id: 3,
    logo: logoBMF,
    department: 'BMF',
    title: 'Leistungsreporting',
    images: [
      leistungsreporting1,
      leistungsreporting2,
      leistungsreporting3,
      leistungsreporting4,
    ],
    description: `Das Leistungsreporting bietet die Möglichkeit, monatliche Kennzahlen in Form von Kurzberichten einzelner Abteilungen kategorisiert nach Themen darzustellen.`,
    fullText: [
      'Das Leistungsreporting bietet die Möglichkeit, monatliche Kennzahlen in Form von Kurzberichten einzelner Abteilungen kategorisiert nach Themen darzustellen.',
      'Die Kennzahlen werden direkt im System eingetragen (ein Kopieren und Einfügen aus Excel ist natürlich möglich) und können von dort aus als Excel oder PDF Export heruntergeladen und bereitgestellt werden. Die Kurzberichte schalten sich für die Bearbeitung am Monatsersten für die Bearbeitung frei. Eingetragenen Kennzahlen durchlaufen einen Freigabeworkflow, bevor sie für den Export herangezogen werden. Kommentarfelder für eingetragenen Kennzahlen pro Berichtszeile runden die Funktionalität ab.',
      'Alle Kennzahlen werden in einem zentralen Power BI Bericht dargestellt, in dem Unterschiede und Crossfilter auf einzelne Monate möglich gemacht werden. Dieser Bericht wird der Führungsebene bereitgestellt, um kompakt Kennzahlen für das aktuelle Monat vorlegen zu können.',
      'Das Leistungsreporting ist mittels PVP-Authentifizierung abgesichert. Somit ist es nur befugten Benutzern möglich in das System einzusteigen, um die sensiblen Daten zu bearbeiten.  ',
    ],
    from: 'Bundesministerium für Finanzen',
    services: ['Portalanbindung', 'Power BI'],
  },
  // {
  //   id: 4,
  //   logo: logoBMF,
  //  department: "BMF",
  //   title: 'BMF – GRC ',
  //   images: [grc1, grc2, grc3, grc4],
  //   description: `Durch diese Softwarelösung wird der bestehende Risikomanagement-Prozess im Bundesministerium für Finanzen mittels automatisierter Workflows standardisiert, effizienter und benutzerfreundlicher gestaltet.`,
  //   fullText: [
  //     'Durch diese Softwarelösung wird der bestehende Risikomanagement-Prozess im Bundesministerium für Finanzen mittels automatisierter Workflows standardisiert, effizienter und benutzerfreundlicher gestaltet. Die Applikation beinhaltet Benachrichtigungs-, Freigabe-, und Genehmigungsfunktionen inklusive Statusangaben zu allen dem System bekannten Risiken, Maßnahmen und Berichten. Die Berichtsgenerierung erfolgt mittels einer zentralen Berichtsvorlage im Normalfall in einem halbjährlichen Zyklus.',
  //     'Durch die Applikation konnten die bis zur Einführung des Tools verwendeten Excel-Dateien abgeschafft und die Berichtserstellung massiv vereinfacht werden. Um die verschiedenen Termine (wie z.B. Termin zur Berichtserstellung) nicht aus den Augen zu verlieren, wird im Tool die Möglichkeit geboten, Termine zu erfassen und diese allen Benutzern zur Verfügung zu stellen. Zusätzlich ist die Anbindung von Fremdsystemen, wie dem bestehenden Kompass-System, auf dem die Benutzerverwaltung basiert, möglich. Hier werden bestehende Benutzer-Logins und deren Rollen abgegriffen und die Kompass-Rollen in applikationsspezifische Rollen umgewandelt. Durch ein definiertes Rollen- und Rechtekonzept werden sämtliche Daten mittels Security-Trimming gefiltert und den Benutzern nur jene Daten zur Verfügung gestellt, die auch für sie bestimmt sind.',
  //   ],
  //   from: 'Bundesministerium für Finanzen',
  //   services: ['Portalanbindung', 'Kompass'],
  // },
  {
    id: 5,
    logo: logoBMAW,
    department: 'BMAW',
    title: 'Sektion3 (Reporting Tool)',
    images: [reportingTool1, reportingTool2, reportingTool3, reportingTool4],
    description: `Das ReportingTool der Sektion 3 bietet dem Bundesministerium für Arbeit und Wirtschaftsstandort die Möglichkeit, Themen und Projekte sowie deren Meilensteine zu erfassen und diese freizugeben.`,
    fullText: [
      'Das ReportingTool der Sektion 3 bietet dem Bundesministerium für Arbeit und Wirtschaft die Möglichkeit, Themen und Projekte sowie deren Meilensteine zu erfassen und diese freizugeben. Die Daten werden direkt im System eingetragen. Mittels Freigabeworkflows können einzelne Themen und Projekte freigegeben und für andere Abteilungen zugänglich gemacht werden. Die Freigabe erfolgt immer über den/die Abteilungsleiter/in.',
      'Es besteht ebenfalls die Möglichkeit Termine, in denen relevante Themen und/oder Projekte hinzugefügt werden, zu erstellen. Diese können dann mit anderen Personen geteilt werden.',
      'Eine Übersichtsseite ermöglicht den leichten Überblick über alle Termine der nächsten 3 Wochen, sowie den aktuellen Status der Themen und Projekte.',
      'Das ReportingTool ist mittels PVP-Authentifizierung abgesichert. Somit ist es nur befugten Benutzern möglich in das System einzusteigen, um die sensiblen Daten zu bearbeiten. In der Applikation werden lediglich Zusatz- und App-spezifische Rollen vergeben.',
    ],
    from: 'Bundesministerium für Arbeit und Wirtschaft',
    services: ['Portalanbindung'],
  },
  // {
  //   id: 6,
  //   logo: logoBMF,
  //   department: 'BMF',
  //   title: 'Kabinett Dashboard',
  //   images: [kabinettDashboard1],
  //   description: `Das Kabinett Dashboard ermöglicht berechtigten Personen die Bereitstellung und Prüfung von Daten aus Vorsystemen, wie z.B. Excel Dateien etc., direkt im System. Die Berichtsempfängerinnen und Berichtsempfänger erhalten somit immer ein aktuelles Bild der für ihre Entscheidungen notwendigen Zahlen.`,
  //   fullText: [
  //     'Das Kabinett Dashboard ermöglicht berechtigten Personen die Bereitstellung und Prüfung von Daten aus Vorsystemen, wie z.B. Excel Dateien etc., direkt im System. Die Berichtsempfängerinnen und Berichtsempfänger erhalten somit immer ein aktuelles Bild der für ihre Entscheidungen notwendigen Zahlen. Die Dateien können beliebig oft im Status Entwurf hochgeladen und mittels Freigabebutton veröffentlicht werden. Die Darstellung der hochgeladenen und freigegebenen Daten wird in einem Power BI Bericht durchgeführt.',
  //     'In diesem Bericht sind Kennzahlen pro Sektion und Abteilung ersichtlich, zusätzlich gibt es Auswertungen für Budget und freigegebene/bezahlte Leistungen in einem Vergleichsdiagramm. Des Weiteren ist es möglich, einzelne Projektelemente zu filtern und damit Budget und abgerufene Leistungen für das jeweilige Projekt anzuzeigen.',
  //     'Das KabinettDashboard ist mittels PVP-Authentifizierung abgesichert. Somit ist es nur befugten Benutzern möglich in das System einzusteigen, um die sensiblen Daten hochzuladen.  ',
  //   ],
  //   from: 'Bundesministerium für Finanzen',
  //   services: ['Portalanbindung'],
  // },
  {
    id: 7,
    logo: logoBMF,
    department: 'BMF',
    title: 'Risikomanagement-Tool',
    images: [
      risikoManagement1,
      risikoManagement2,
      risikoManagement3,
      risikoManagement4,
      risikoManagement5,
      risikoManagement6,
    ],
    pdfs: [
      {
        title: 'Gesamtübersicht_DEMO.pdf',
        src: risikoManagementPDF1,
      },
      {
        title: 'Risiken_DEMO.pdf',
        src: risikoManagementPDF2,
      },
      {
        title: 'Massnahmen_DEMO.pdf',
        src: risikoManagementPDF3,
      }
    ],
    description: `Das Risikomanagement-Tool ermöglich die Erfassung und das Monitoring von Risiken und Maßnahmen. Regelmäßige Bewertungen der Risiken können rasch und einfach erfolgen. Grafische Darstellungen und vordefinierte Berichte stehen für die rasche Übersicht zur Verfügung.`,
    lists: [
      {
        title: 'Das Risikomanagement-Tool ist nützlich für alle…',
        bulletPoints: [
          'die im Rahmen ihrer Arbeit (zB: bei Projekten) mit Risiken konfrontiert werden.',
          'die den Fokus auf die Definition und Bewertung der Risiken richten wollen.',
          'die Risiken mit definierten Maßnahmen entgegen steuern wollen.',
          'die den Verlauf von Risiken abbilden möchten.',
        ],
      },
      {
        title: 'Das Risikomanagement-Tool ermöglicht…',
        bulletPoints: [
          'eine standardisierte Bewertung der Risiken.',
          'eine Zuordnung von Maßnahmen mit Terminkontrolle.',
          'eine graphische Darstellung der Risiken.',
          'die Erstellung von Berichten.',
        ],
      },
      {
        title: 'Das Risikomanagement-Tool erleichtert das Arbeiten,',
        bulletPoints: [
          'weil es zentral verfügbar ist.',
          'weil jeder transparent zur gleichen Zeit Zugang zu allen Informationen hat.',
          'weil niemand auf Berichte warten muss.',
          'weil es die Anzahl der versendeten E-Mails reduziert.',
          'weil es graphische Darstellungen anbietet.',
          'weil es eine einheitliche Berichterstattung ermöglicht.',
        ],
      },
    ],
    from: 'Bundesministerium für Finanzen',
    services: ['Portalanbindung'],
  },
];
