import React, { Component } from 'react';
import { Times } from '../../helpers/svgs';

class DataPrivacy extends Component {
  render() {
    return (
      <div className={'imprint' + (this.props.visible ? '' : ' hide')} role="dialog">
        <div className="imprint-card">
          <button
            className="close-image-modal-btn"
            onClick={this.props.closeImageModal}
            data-dismiss="modal"
            aria-label="Schließen"
          >
            {Times}
          </button>

          <h2>
            <span class="title">Datenschutz</span>
          </h2>

          <p class="abstract">
            Für das Bundeskanzleramt Österreich (
            <abbr title="Bundeskanzleramt Österreich">BKA</abbr>) und alle datenschutzrechtlich
            verantwortlichen Stellen des Finanzressorts hat der Schutz von personenbezogenen Daten
            eine hohe Priorität. Wir achten darauf, personenbezogene Daten in Einklang mit allen
            anwendbaren europarechtlichen und österreichischen Rechtsvorschriften zu verarbeiten und
            nehmen unsere Verantwortung sehr ernst.&nbsp; <br />
            Wir möchten, dass Sie wissen, zu welchen Zwecken und auf welchen Rechtsgrundlagen wir
            personenbezogene Daten erheben und wie wir diese Daten verarbeiten. Wir möchten Sie
            darüber hinaus über Ihre Rechte in Datenschutzangelegenheiten informieren und Ihnen
            mitteilen, an wen Sie sich diesbezüglich wenden können.&nbsp; <br />
            Da im Laufe der Zeit Änderungen der Datenschutzerklärung notwendig werden können,
            empfehlen wir Ihnen, sich diese ab und zu erneut durchzulesen.&nbsp;
          </p>
          <h4>Inhaltsverzeichnis</h4>

          <ul>
            {/*<li>
              <a href="#Download_der_Datenschutzerklärung">Download der Datenschutzerklärung</a>
            </li>*/}
            <li>
              <a href="#Allgemeines_zum_Datenschutz_in_Oesterreich">
                Allgemeines zum Datenschutz in Österreich
              </a>
              <ul>
                <li>
                  <a href="#Was_regelt_die_Datenschutz-Grundverordnung">
                    Was regelt die Datenschutz-Grundverordnung?
                  </a>
                </li>
                <li>
                  <a href="#Was_regelt_das_Datenschutzgesetz">Was regelt das Datenschutzgesetz?</a>
                </li>
                <li>
                  <a href="#Was_sind_personenbezogene_Daten">Was sind personenbezogene Daten?</a>
                </li>
                <li>
                  <a href="#Was_bedeutet_der_Begriff_Verarbeitung">
                    Was bedeutet der Begriff „Verarbeitung“?
                  </a>
                </li>
                <li>
                  <a href="#Was_bedeutet_der_Begriff_Verantwortlicher">
                    Was bedeutet der Begriff „Verantwortlicher“?
                  </a>
                </li>
                <li>
                  <a href="#Was_bedeutet_der_Begriff_Auftragsverarbeiter">
                    Was bedeutet der Begriff „Auftragsverarbeiter“?
                  </a>
                </li>
                <li>
                  <a href="#Wo_finden_Sie_weiterführende_Informationen_zum_Datenschutz">
                    Wo finden Sie weiterführende Informationen zum Datenschutz?
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#Verarbeitung_personenbezogener_Daten">
                Verarbeitung personenbezogener Daten
              </a>
              <ul>
                <li>
                  <a href="#Wer_sind_Ihre_Ansprechpartner">Wer sind Ihre Ansprechpartner?</a>
                </li>
                <li>
                  <a href="#Wer_ist_Datenschutzbeauftragter_im_Finanzressort">
                    Wer ist Datenschutzbeauftragter im Finanzressort?
                  </a>
                </li>
                <li>
                  <a href="#Was_passiert_beim_Aufruf_unserer_Webseiten">
                    Was passiert beim Aufruf unserer Webseiten?
                  </a>
                </li>
                <li>
                  <a href="#Welchen_Sicherheitsstandards_unterliegt_die_Datenverarbeitung">
                    Welchen Sicherheitsstandards unterliegt die Datenverarbeitung?
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#Datenschutzrechte">Datenschutzrechte</a>
              <ul>
                <li>
                  <a href="#Welche_Datenschutzrechte_haben_Sie">
                    Welche Datenschutzrechte haben Sie?
                  </a>
                </li>
                <li>
                  <a href="#Wie_können_Sie_Ihren_Antrag_einbringen">
                    Wie können Sie Ihren Antrag einbringen?
                  </a>
                </li>
                <li>
                  <a href="#Wie_lange_dauert_die_Bearbeitung_Ihres_Antrags">
                    Wie lange dauert die Bearbeitung Ihres Antrags?
                  </a>
                </li>
                <li>
                  <a href="#Wie_wird_Ihr_Antrag_beantwortet">Wie wird Ihr Antrag beantwortet?</a>
                </li>
                <li>
                  <a href="#Welche_Beschwerdemöglichkeiten_haben_Sie">
                    Welche Beschwerdemöglichkeiten haben Sie?
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          {/*
          <h4 id="Download_der_Datenschutzerklärung">Download der Datenschutzerklärung</h4>

          <p>
            <a
              href="https://www.bmf.gv.at/dam/jcr:ce410391-df7e-4f51-9579-a66f4cc85183/Datenschutzerkl%C3%A4rung%20des%20BMF-2022-08-01.pdf"
              class="file"
              download=""
            >
              <span class="icon icon-datei_pdf" aria-hidden="true"></span>Download der
              Datenschutzerklärung im PDF-Format (Stand 1. August 2022){' '}
              <span class="fileinfo">(PDF, 253 KB)</span>
            </a>
          </p>
          */}
          <h4 id="Allgemeines_zum_Datenschutz_in_Oesterreich">
            Allgemeines zum Datenschutz in Österreich
          </h4>

          <p>
            Datenschutz ist ein Grundrecht, das in der Charta der Grundrechte der Europäischen Union
            und in § 1 <abbr title="Datenschutzgesetz">DSG</abbr> verankert ist. Ab 25. Mai 2018
            gilt in der Europäischen Union die Datenschutz-Grundverordnung (
            <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>). Zum gleichen Zeitpunkt tritt in
            Österreich das neue Datenschutzgesetz (<abbr title="Datenschutzgesetz">DSG</abbr>) in
            Kraft.&nbsp;
          </p>

          <h5 id="Was_regelt_die_Datenschutz-Grundverordnung">
            Was regelt die Datenschutz-Grundverordnung?
          </h5>

          <p>
            Die <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> ist eine Verordnung der
            Europäischen Union und gilt unmittelbar in jedem Mitgliedsstaat, also auch in
            Österreich. Die <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> enthält
            Vorschriften über die Verarbeitung von personenbezogenen Daten, wie z.B. die Grundsätze
            für die Verarbeitung, die Rechte der betroffenen Person sowie die Pflichten der
            Verantwortlichen und der Auftragsverarbeiter.&nbsp;
          </p>

          <h5 id="Was_regelt_das_Datenschutzgesetz">Was regelt das Datenschutzgesetz?</h5>

          <p>
            Das <abbr title="Datenschutzgesetz">DSG</abbr> ist ein österreichisches Gesetz und
            enthält Regelungen zur Ergänzung der{' '}
            <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> sowie Vorschriften zur Umsetzung
            der Richtlinie (EU) 2016/680 des Europäischen Parlaments und des Rates vom 27. April
            2016 über die Verarbeitung personenbezogener Daten durch zuständige Behörden zum Zweck
            der Verhütung, Ermittlung, Aufdeckung oder Verfolgung von Straftaten oder der
            Strafvollstreckung, einschließlich des Schutzes vor und der Abwehr von Gefahren für die
            öffentliche Sicherheit, sowie zum Zweck der nationalen Sicherheit, des
            Nachrichtendienstes und der militärischen Eigensicherung.&nbsp;
          </p>

          <h5 id="Was_sind_personenbezogene_Daten">Was sind personenbezogene Daten?</h5>

          <p>
            Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person („betroffene Person“) beziehen. Als identifizierbar
            gilt eine natürliche Person, die direkt oder indirekt identifiziert werden kann,{' '}
            <abbr title="zum Beispiel">z.B.</abbr> mittels Zuordnung zu einem Namen oder einer
            Kennnummer (beispielsweise Steuernummer, Sozialversicherungsnummer und Kontonummer).
          </p>

          <p>
            Nachzulesen im Artikel 4 Ziffer 1 <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>
            .&nbsp;
          </p>

          <h5 id="Was_bedeutet_der_Begriff_Verarbeitung">
            Was bedeutet der Begriff „Verarbeitung“?
          </h5>

          <p>
            Der Begriff „Verarbeitung“ meint jeden mit oder ohne Hilfe automatisierter Verfahren
            ausgeführten Vorgang im Zusammenhang mit personenbezogenen Daten. Dazu zählen{' '}
            <abbr title="zum Beispiel">z.B.</abbr> das Erheben, das Erfassen, die Organisation, das
            Ordnen, das Speichern, das Anpassen oder Verändern, das Auslesen, das Abfragen, das
            Verwenden, das Offenlegen durch Übermittlung, Verbreitung oder eine andere Form der
            Bereitstellung, der Abgleich oder das Verknüpfen, das Einschränken, das Löschen oder das
            Vernichten von personenbezogenen Daten.
          </p>

          <p>
            Nachzulesen im Artikel 4 Ziffer 2 <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>
            .&nbsp;
          </p>

          <h5 id="Was_bedeutet_der_Begriff_Verantwortlicher">
            Was bedeutet der Begriff „Verantwortlicher“?
          </h5>

          <p>
            Der Begriff „Verantwortlicher“ meint die natürliche oder juristische Person, Behörde,
            Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
            und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.&nbsp;
          </p>

          <p>
            Nachzulesen im Artikel 4 Ziffer 7 <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>
            .&nbsp;
          </p>

          <h5 id="Was_bedeutet_der_Begriff_Auftragsverarbeiter">
            Was bedeutet der Begriff „Auftragsverarbeiter“?
          </h5>

          <p>
            Der Begriff „Auftragsverarbeiter“ meint eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
            Verantwortlichen verarbeitet.&nbsp;
          </p>

          <p>
            Nachzulesen im Artikel 4 Ziffer 8 <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>
            .&nbsp;
          </p>

          <h5 id="Wo_finden_Sie_weiterführende_Informationen_zum_Datenschutz">
            Wo finden Sie weiterführende Informationen zum Datenschutz?
          </h5>

          <p>
            Den{' '}
            <a
              href="http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;amp;from=DE"
              target="_blank"
              rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              gesamten Text der <abbr title="Datenschutzgrundverordnung">DSGVO</abbr>
            </a>{' '}
            finden Sie auf EUR-Lex unter{' '}
            <a
              href="https://eur-lex.europa.eu/"
              target="_blank"
              rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              eur-lex.europa.eu
            </a>
            . Den{' '}
            <a
              href="http://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;amp;Gesetzesnummer=10001597&amp;amp;FassungVom=2018-05-25"
              target="_blank"
              rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              gesamten Text des <abbr title="Datenschutzgesetzes">DSG</abbr>
            </a>{' '}
            sowie den{' '}
            <a
              href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;amp;Gesetzesnummer=10003898&amp;amp;FassungVom=2018-05-25"
              target="_blank"
              rel="noopener noreferrer"
              title="Öffnet in einem neuen Fenster"
            >
              gesamten Text des Finanzstrafgesetzes
            </a>{' '}
            in der Fassung vom 25. Mai 2018 finden Sie im Rechtsinformationssystem des Bundes unter{' '}
            <a
              href="http://www.ris.bka.gv.at/"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              www.ris.bka.gv.at
            </a>
            . Weitere Informationen zum Datenschutz finden Sie darüber hinaus auf der Webseite der
            Datenschutzbehörde unter{' '}
            <a
              href="http://www.dsb.gv.at/"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              www.dsb.gv.at
            </a>
            .&nbsp;&nbsp;
          </p>

          <h4 id="Verarbeitung_personenbezogener_Daten">Verarbeitung personenbezogener Daten</h4>

          <p>Über diesen Webauftritt werden von uns keine personenbezogenen Daten erfasst.</p>

          <h5 id="Wer_sind_Ihre_Ansprechpartner">Wer sind Ihre Ansprechpartner?</h5>

          <p>
            Fragen und Anliegen in datenschutzrechtlichen Angelegenheiten können Sie an die
            Leiterinnen und Leiter der für die Verarbeitung Ihrer personenbezogenen Daten jeweils verantwortlichen Stelle 
            oder an den Datenschutzbeauftragten des <abbr title="Bundeskanzleramt Österreich">BKA</abbr> 
            richten, insoweit nicht die justizielle Tätigkeit des Bundesfinanzgerichtes betroffen ist.
          </p>

          <p>
            Die Kontaktdaten des <abbr title="Bundeskanzleramt Österreich">BKA</abbr> finden
            Sie&nbsp;unter{' '}
            <a href="https://www.bka.gv.at" target="_blank" rel="noopener noreferrer" title="Öffnet in einem neuen Fenster">
              https://www.bka.gv.at
            </a>
            &nbsp;in der Rubrik&nbsp;Kontakt.
          </p>
          {/*
          <h5 id="Wer_ist_Datenschutzbeauftragter_im_Finanzressort">
            Wer ist Datenschutzbeauftragter im Finanzressort?
          </h5>

          <p>
            Der Datenschutzbeauftragte des <abbr title="Bundesministeriums für Finanzen">BMF</abbr>{' '}
            fungiert als <strong>Datenschutzbeauftragter für das gesamte Finanzressort</strong> und
            steht Ihnen bei Fragen in datenschutzrechtlichen Angelegenheiten zur Verfügung.&nbsp;
          </p>

          <p>
            <strong>Kontaktdaten:</strong> <br />
            <abbr title="Doktor">Dr.</abbr> Stefan Lang <br />
            Johannesgasse 5, 1010 Wien <br />
            E-Mail: datenschutz@bmf.gv.at <br />
            Web:{' '}
            <a
              href="https://www.bmf.gv.at/"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              www.bmf.gv.at
            </a>
            &nbsp;
          </p>
            */}
          <h5 id="Was_passiert_beim_Aufruf_unserer_Webseiten">
            Was passiert beim Aufruf unserer Webseiten?
          </h5>

          <p>
            Beim Aufruf unserer Webseiten werden Ihre{' '}
            <span dir="ltr" lang="en">
              <abbr title="Internet Protocol">IP</abbr>
            </span>
            -Adresse, Datum und Uhrzeit des Aufrufs, Verbindungsinformationen (Zieladresse,
            aufgerufene Dateien, übertragene Datenmengen und Status) sowie Informationen zum
            Betriebssystem und Webbrowser{' '}
            <strong>für eine begrenzte Zeit in einer Protokolldatei </strong>gespeichert. Die
            Erfassung dieser Daten ist für den Betrieb der Webseiten zwingend erforderlich,
            insbesondere für die Gewährleistung der Stabilität und Sicherheit.&nbsp;
          </p>

          <p>
            Wir setzen auf unsere Webseiten&nbsp;einen <strong>Webanalysedienst</strong> ein, wobei
            wir{' '}
            <span dir="ltr" lang="en">
              <abbr title="Internet Protocol">IP</abbr>
            </span>
            -Adressen unverzüglich <strong>anonymisieren</strong>. Die Analyse dient ausschließlich
            der Optimierung unseres Webangebotes.&nbsp;
          </p>

          <h5 id="Welchen_Sicherheitsstandards_unterliegt_die_Datenverarbeitung">
            Welchen Sicherheitsstandards unterliegt die Datenverarbeitung?
          </h5>

          <p>
            Wir verarbeiten personenbezogene Daten mit <strong>höchster Sorgfalt </strong>und haben
            umfangreiche technische und organisatorische <strong>Sicherheitsmaßnahmen</strong>{' '}
            getroffen, um sicherzustellen, dass die geltenden Datenschutzvorschriften sowohl von
            allen verantwortlichen Stellen als auch von den von uns beauftragten
            Auftragsverarbeitern beachtet und eingehalten werden.&nbsp;
          </p>

          <p>
            Dies betrifft insbesondere den Schutz von personenbezogenen Daten vor unbefugter oder
            unrechtmäßiger Verarbeitung, unbeabsichtigter oder unrechtmäßiger Vernichtung, Verlust
            oder Veränderung, unbefugter Offenlegung von bzw. unbefugtem Zugang zu personenbezogenen
            Daten, die übermittelt, gespeichert oder auf andere Weise verarbeitet werden. Die
            Sicherheitsmaßnahmen entsprechen dem aktuellen <strong>Stand der Technik </strong>und
            umfassen unter anderem den Einsatz moderner Sicherheitstechnologien und
            Verschlüsselungsverfahren, physischer Zutrittskontrollen und Vorkehrungen zur Abwehr von
            Angriffen.&nbsp;
          </p>

          <p>
            Der Datenschutz und die Informationssicherheit hatten für uns bereits vor dem 25. Mai
            2018 eine hohe Priorität. Aus diesem Grund haben wir schon im Jahr 2008 ein{' '}
            <a
              href="https://de.wikipedia.org/wiki/Information_Security_Management_System"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              Informationssicherheits-Managementsystem
            </a>{' '}
            (<abbr title="Informationssicherheits-Managementsystem">ISMS</abbr>) implementiert, das
            nach dem internationalen Sicherheitsstandard{' '}
            <a
              href="https://de.wikipedia.org/wiki/ISO/IEC_27001"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              ISO 27001
            </a>{' '}
            zertifiziert ist und jährlich überprüft wird. Damit waren wir das erste
            Bundesministerium in Europa, das eine solche Zertifizierung erlangt hat. Seit Dezember
            2020 verfügen wir darüber hinaus als erste Organisation in Österreich über eine
            Zertifizierung unseres Datenschutz-Managementsystems (
            <abbr title="Datenschutz-Managementsystem">DSMS</abbr>) nach dem internationalen
            Datenschutzstandard ISO 27701. Das{' '}
            <abbr title="Informationssicherheits-Managementsystem">ISMS</abbr> und das{' '}
            <abbr title="Datenschutz-Managementsystem">DSMS</abbr> sorgen unter anderem dafür, dass
            bestehende Risiken systematisch identifiziert, beurteilt und mittels geeigneter
            Maßnahmen behandelt werden. Es stellt darüber&nbsp; hinaus sicher, dass die Wirksamkeit
            der Maßnahmen regelmäßig überprüft, bewertet und evaluiert wird.
          </p>

          <h4 id="Datenschutzrechte">Datenschutzrechte</h4>

          <p>
            Die <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> und das{' '}
            <abbr title="Datenschutzgesetz">DSG</abbr> regeln auch die Datenschutzrechte der
            betroffenen Personen, also derjenigen, deren personenbezogene Daten verarbeitet werden.
            Der Rechtsanspruch der betroffenen Person richtet sich gegen den Verantwortlichen, also
            gegen die für die Datenverarbeitung verantwortliche Stelle.&nbsp;
          </p>

          <h5 id="Welche_Datenschutzrechte_haben_Sie">Welche Datenschutzrechte haben Sie?</h5>

          <p>
            Nach der <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> und dem{' '}
            <abbr title="Datenschutzgesetz">DSG</abbr> stehen Ihnen verschiedene Rechte zu,
            insbesondere:
          </p>

          <ul>
            <li>
              das Recht die <strong>Einwilligung</strong> zur Verarbeitung Ihrer personenbezogenen
              Daten jederzeit <strong>zu widerrufen</strong>, sofern die Verarbeitung durch uns auf
              Grundlage Ihrer Einwilligung erfolgt;
            </li>
            <li>
              das Recht auf <strong>Auskunft</strong> darüber, ob von uns Sie betreffende
              personenbezogene Daten verarbeitet werden und welchen Inhalt diese haben, sowie das
              Recht auf <strong>Berichtigung</strong> <abbr title="beziehungsweise">bzw.</abbr>{' '}
              <strong>Vervollständigung</strong> und auf <strong>Löschung</strong> Ihrer
              personenbezogenen Daten, auf <strong>Einschränkung der Verarbeitung</strong>, auf{' '}
              <strong>Widerspruch gegen die Verarbeitung </strong>sowie auf{' '}
              <strong>Datenübertragbarkeit</strong>, sofern die gesetzlichen Voraussetzungen dafür
              vorliegen.&nbsp;
            </li>
          </ul>

          <p>
            Die jeweiligen gesetzlichen Voraussetzungen sowie etwaige Ausnahmen und Beschränkungen
            dieser Rechte ergeben sich aus den Artikeln 12 bis 22{' '}
            <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> bzw. aus den §§ 42 bis 45{' '}
            <abbr title="Datenschutzgesetz">DSG</abbr> sowie aus den Rechtsvorschriften, die der
            jeweiligen Datenverarbeitung zu Grunde liegen. Gemäß § 48f Bundesabgabenordnung (
            <abbr title="Bundesabgabenordnung">BAO</abbr>) und § 57b Finanzstrafgesetz (FinStrG)
            besteht für personenbezogene Daten, die auf Grundlage der{' '}
            <abbr title="Bundesabgabenordnung">BAO</abbr> oder des{' '}
            <abbr title="Finanzstrafgesetz">FinStrG</abbr> verarbeitet werden und in einem Akt
            enthalten sind, das Recht auf Auskunft ausschließlich nach Maßgabe des § 90{' '}
            <abbr title="Bundesabgabenordnung">BAO</abbr> oder § 79{' '}
            <abbr title="Finanzstrafgesetz">FinStrG</abbr> (Akteneinsicht). Die Akteneinsicht
            gewährt Ihnen das Recht, die Einsicht und Abschriftnahme Ihrer Akten (Aktenteile) zu
            verlangen, deren Kenntnis zur Wahrung Ihrer Rechte und Erfüllung Ihrer Pflichten im
            Rahmen der Abgaben- und Finanzstrafverfahren erforderlich ist. Weitere spezifische
            datenschutzrechtliche Bestimmungen bzw. Beschränkungen ergeben sich insbesondere aus den
            §§ 48d bis 48i <abbr title="Bundesabgabenordnung">BAO</abbr> und §§ 57c und 57d{' '}
            <abbr title="Finanzstrafgesetz">FinStrG</abbr>.
          </p>

          <p>
            In jenen Fällen, in denen gesetzliche Ausnahmen{' '}
            <abbr title="beziehugnsweise">bzw.</abbr> Beschränkungen dieser Rechte bestehen, dürfen
            wir Ihrem Antrag nicht bzw. nur eingeschränkt entsprechen. Sofern dies gesetzlich
            zulässig ist, teilen wir Ihnen in diesem Fall den Grund für die Verweigerung bzw.
            Einschränkung mit.&nbsp;
          </p>

          <h5 id="Wie_können_Sie_Ihren_Antrag_einbringen">
            Wie können Sie Ihren Antrag einbringen?
          </h5>

          <p>
            Sie können Ihren Antrag zur Geltendmachung Ihrer Datenschutzrechte bei der für die
            Verarbeitung Ihrer personenbezogenen Daten verantwortlichen Stelle auf folgende Arten
            einbringen:
          </p>

          <ul>
            <li>
              <strong>per Brief oder Fax</strong> <br />
              erforderlich dafür ist die Beilage einer Kopie eines amtlichen Lichtbildausweises (
              <abbr title="zum Beispiel">z. B.</abbr> Reisepass oder Personalausweis)
            </li>
            <li>
              <strong>mittels FinanzOnline</strong> <br />
              in abgabenrechtlichen Angelegenheiten
            </li>
            <li>
              <strong>persönlich</strong> <br />
              erforderlich dafür ist die Vorlage eines amtlichen Lichtbildausweises (
              <abbr title="zum Beispiel">z. B.</abbr> Reisepass oder Personalausweis)
            </li>
          </ul>

          <p>
            Bitte verfassen Sie Ihren Antrag so konkret wie möglich. Nur so können wir diesen
            effizient und rasch bearbeiten. Beachten Sie auch, dass im Anwendungsbereich der
            Bundesabgabenordnung (<abbr title="Bundesabgabenordnung">BAO</abbr>) in
            Auskunftsanträgen gemäß § 48f <abbr title="Bundesabgabenordnung">BAO</abbr> zu
            präzisieren ist, auf welche Informationen oder Verarbeitungsvorgänge sich das
            Auskunftsersuchen bezieht.&nbsp;
          </p>

          <p>
            Soweit das Auskunftsbegehren personenbezogene Daten betrifft, die in einem Akt enthalten
            sind, ist gemäß § 48f Abs. 2 <abbr title="Bundesabgabenordnung">BAO</abbr> das
            Akteneinsichtsverfahren der <abbr title="Bundesabgabenordnung">BAO</abbr> maßgeblich.
            Auf elektronischem Weg ist die Antragstellung daher nur über FinanzOnline möglich. Auch
            für das Recht auf Berichtigung gemäß Art. 16{' '}
            <abbr title="Datenschutzgrundverordnung">DSGVO</abbr> ist in den Fällen des § 48g Abs. 1{' '}
            <abbr title="Bundesabgabenordnung">BAO</abbr> das Verfahren nach den Vorschriften der{' '}
            <abbr title="Bundesabgabenordnung">BAO</abbr> durchzuführen.
          </p>

          <p>
            Wir ersuchen Sie darüber hinaus um Verständnis dafür, dass wir in Zweifelsfällen weitere
            Angaben zu Ihrer Identität verlangen müssen. Dies dient ausschließlich dem Schutz Ihrer
            personenbezogenen Daten und soll sicherstellen, dass nur Sie selbst Auskunft über Ihre
            personenbezogenen Daten erhalten.&nbsp;
          </p>

          <h5 id="Wie_lange_dauert_die_Bearbeitung_Ihres_Antrags">
            Wie lange dauert die Bearbeitung Ihres Antrags?
          </h5>

          <p>
            Wir werden Ihnen ehestmöglich, jedenfalls aber <strong>innerhalb eines Monats </strong>
            nach Eingang Ihres Antrags, die entsprechenden Informationen über die Maßnahmen zur
            Verfügung stellen.&nbsp;&nbsp;
          </p>

          <p>
            Beachten Sie bitte, dass diese Frist &nbsp;um <strong>weitere zwei Monate </strong>
            verlängert werden kann, wenn dies unter Berücksichtigung der Komplexität bzw. der Anzahl
            von Anträgen erforderlich ist. Wir informieren Sie aber jedenfalls innerhalb eines
            Monats nach Eingang Ihres Antrags über eine etwaige Fristverlängerung und über die
            Gründe dafür.&nbsp;
          </p>

          <h5 id="Wie_wird_Ihr_Antrag_beantwortet">Wie wird Ihr Antrag beantwortet?</h5>

          <p>
            Personenbezogene Daten sind Vertrauenssache. Da eine unverschlüsselte E-Mail leider
            nicht als sicher eingestuft werden kann und eher mit einer Postkarte als mit einem Brief
            vergleichbar ist, übermitteln wir die Beantwortung Ihres Antrags niemals per E-Mail. Sie
            erhalten die Beantwortung daher <strong>per Post </strong>(RSa) oder in steuerlichen
            Angelegenheiten gegebenenfalls mittels <strong>FinanzOnline Databox</strong>.&nbsp;
          </p>

          <h5 id="Welche_Beschwerdemöglichkeiten_haben_Sie">
            Welche Beschwerdemöglichkeiten haben Sie?
          </h5>

          <p>
            Bei Fragen, Anregungen und Beschwerden in Datenschutzangelegenheiten steht Ihnen der
            Datenschutzbeauftragte des <abbr title="Bundesministeriums für Finanzen">BMF</abbr> zur
            Verfügung.
          </p>

          <p>
            Wenn Sie der Auffassung sind, dass die Verarbeitung Ihrer personenbezogenen Daten durch
            uns gegen Datenschutzvorschriften verstößt oder Ihre datenschutzrechtlichen Ansprüche
            sonst in einer Weise verletzt worden sind, können Sie bei der{' '}
            <strong>zuständigen Aufsichtsbehörde </strong>Beschwerde einlegen. In Österreich ist
            dies die Datenschutzbehörde.&nbsp;
          </p>

          <p>
            <strong>Kontaktdaten:</strong> <br />
            Österreichische Datenschutzbehörde <br />
            Barichgasse 40-42, 1030 Wien <br />
            Telefon: +43 1 52 152 0 <br />
            E-Mail:&nbsp;dsb@dsb.gv.at <br />
            Web:{' '}
            <a
              href="https://www.dsb.gv.at/"
              target="_blank"
              rel="noopener noreferrer"
              title="Link öffnet in einem neuen Fenster"
            >
              www.dsb.gv.at
            </a>
          </p>

          <p>
            Wenn Sie der Auffassung sind, das Bundesfinanzgericht habe Sie in Ausübung seiner
            gerichtlichen Zuständigkeiten in ihren Rechten nach der Datenschutz-Grundverordnung
            verletzt, können Sie beim Bundesfinanzgericht schriftlich oder mit Telefax Beschwerde
            erheben.
          </p>

          <p>
            <strong>Kontaktdaten:</strong> <br />
            Bundesfinanzgericht <br />
            Hintere Zollamtsstraße 2b <br />
            1030 Wien <br />
            Telefon: +43 (0) 50250 577100 <br />
            Telefax: +43 (0) 50250 5977100
          </p>

          <p>
            Weitere Informationen zum Datenschutz in den Bereichen Österreichs Digitales Amt,
            Unternehmensserviceportal, Terminvereinbarungstool – Termino, Digital Austria,
            IKT-Sicherheitsportal, Stammzahlenregisterbehörde und Fernmeldebüro finden Sie unter:
          </p>

          <ul>
            <li>
              <a href="https://www.oesterreich.gv.at/ueber-oesterreichgvat/datenschutz.html">
                oesterreich.gv.at und die App "Digitales Amt"
              </a>
            </li>
            <li>
              <a href="https://www.usp.gv.at/ueber-das-usp/index/kontakt/datenschutzerklaerung.html">
                Unternehmenserviceportal - USP
              </a>
            </li>
            <li>
              <a href="https://www.termino.gv.at/meet/de/privatsphaere">
                Terminvereinbarungstool - Termino
              </a>
            </li>
            <li>
              <a href="https://www.digitalaustria.gv.at/datenschutz.html">Digital Austria</a>
            </li>
            <li>
              <a href="https://www.onlinesicherheit.gv.at/Impressum/Initiatoren.html">
                IKT-Sicherheitsportal
              </a>
            </li>
            <li>
              <a href="https://www.bmaw.gv.at/Services/Formulare/Formulare-Stammzahlenregisterbeh%C3%B6rde.html">
                Stammzahlenregisterbehörde
              </a>
            </li>
            <li>
              <a href="https://www.fb.gv.at/Info/datenschutzinformation.html">Fernmeldebüro</a>
            </li>
          </ul>

          <p>Stand: 1. August 2022</p>

          {/* <h4>Datenschutzinformation</h4>
          <h5>Kontakt mit uns</h5>
          <p>
            Die Webauftritte des Bundesministeriums für Finanzen und
            Wirtschaftsstandort (BMF) unterliegen den Bestimmungen der {" "}
            <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=CELEX%3A32016R0679">
              Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates
              vom 27. April 2016 zum Schutz natürlicher Personen bei der
              Verarbeitung personenbezogener Daten, zum freien Datenverkehr und
              zur Aufhebung der Richtlinie 95/46/EG
            </a>{" "}
             (DSGVO) sowie des durch das Datenschutz-Anpassungsgesetz
            novellierten {" "}
            <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&FassungVom=2018-05-25">
              Datenschutzgesetzes idF vom 25. Mai 2018
            </a>
            .
          </p>
          <p>Das BMAW ist „Verantwortlicher“ im Sinn der DSGVO der Daten. </p>
          <p>
            Rechtsgrundlage für die Speicherung und Verarbeitung
            personenbezogener Daten in den Webauftritten des BMAW ist, wo nicht
            explizit anders angegeben, das Bundesministeriengesetz 1986, BGBl.
            Nr. 76/1986 idgF, Teil 1 der Anlage zu § 2 (Angelegenheiten der
            Information über den Ressortbereich einschließlich des Verkehrs mit
            der Presse, dem Hörfunk und dem Fernsehen).
          </p>
          <h5>Personenbezogene Daten, die Sie uns bekanntgeben</h5>
          <p>
            Über diesen Webauftritt werden von uns keine personenbezogenen Daten
            erfasst.
          </p>
          <h5>Personenbezogene Daten, die wir veröffentlichen</h5>
          <p>
            Eine Veröffentlichung von personenbezogenen Daten (insbesondere
            Namen) in den Webauftritten des BMAW (beispielsweise Mitglieder von
            Kommissionen und Arbeitsgruppen, Ansprechpersonen für verschiedene
            Themen, Behördenleitungen, Leitungen der Medienstellen) erfolgt zum
            Zweck von Kontakt- und Vernetzungsinformationen beziehungsweise zur
            Dokumentation.
          </p>
          <p>
            Eine Veröffentlichung von personenbezogenen Daten in
            Pressenachrichten erfolgt im öffentlichen Interesse auf Basis der
            Rechtsgrundlage Artikel 6 Abs. 1 lit. e DSGVO.
          </p>
          <p>
            Weitere Informationen entnehmen Sie bitte der Seite{" "}
            <a href="https://www.bmf.gv.at">
              https://www.bmf.gv.at
            </a>
            .
          </p>
          <h5>Serverlogs</h5>
          <p>
            Jeder Ihrer Zugriffe auf die Websites des BMAW wird in einer
            Protokolldatei (Serverlogs) für ein Monat mit folgenden Daten
            gespeichert:
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>Anfragedetails und Zieladresse</li>
            <li>Name der abgerufenen Datei und übertragene Datenmenge</li>
            <li>Datum und Uhrzeit des Abrufs</li>
            <li>Meldung, ob der Abruf erfolgreich war</li>
            <li>Meldung der gesendeten Bytes</li>
            <li>
              Meldung, ob die Seite über eine Drittseite oder direkt aufgerufen
              wurde
            </li>
            <li>Browserinformation</li>
          </ul>
          <p>
            Diese Daten dienen ausschließlich der Überprüfung der
            Systemsicherheit. Es erfolgt keine personenbezogene Auswertung oder
            Profilbildung.
          </p>
          <p>
            Dessen ungeachtet, behalten wir uns vor, Ihre IP-Adresse bei
            Angriffen auf die Internetinfrastruktur des BMAW auszuwerten.
          </p>
          <h5>Cookies</h5>
          <p>
            Cookies sind kleine Textdateien, die auf dem Computer der
            Anfragenden gespeichert werden. Die in den Cookies enthaltenen
            Informationen dienen der Sitzungssteuerung, der Lastverteilung der
            Server, zu Analysezwecken und zu der Erhöhung der Sicherheit unserer
            Anwendungen sowie der Anpassbarkeit und Bedienbarkeit. Es werden
            keine personenbezogenen Daten in den Cookies gespeichert.
          </p>
          <p>
            Sie können die Verwendung von Cookies durch eine entsprechende
            Einstellung Ihrer Browsersoftware verhindern. Wenn Sie keine Cookies
            zulassen, können Sie unsere Webauftritte trotzdem benutzen.
          </p>
          <h5>Nutzerprofile</h5>
          <p>Es werden keine personenbezogenen Nutzerprofile erstellt. </p>
          <h5>Weitergabe personenbezogener Daten an Dritte </h5>
          <p>
            Daten, die beim Zugriff auf das Internetangebot des BMAW
            protokolliert wurden, werden an Dritte nur übermittelt, soweit wir
            gesetzlich oder durch Gerichtsentscheidung dazu verpflichtet sind
            oder dies im Falle von Angriffen auf die Internetinfrastruktur zur
            Rechts- oder Strafverfolgung erforderlich ist.
          </p>
          <p>
            Im Falle der Bestellung von Informationsmaterial, Broschüren oder
            Produkten verwenden wir die von Ihnen eingegebenen personenbezogenen
            Informationen nur innerhalb des Ressorts und den mit der Abwicklung
            der Bestellung beauftragten Unternehmen.
          </p>
          <p>
            Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen
            Zwecken, findet darüber hinaus ohne Ihre ausdrückliche Einwilligung
            nicht statt.
          </p>
          <h5>Minderjährigenschutz</h5>
          <p>
            Personen unter 14 Jahren sollten ohne Zustimmung der Eltern oder
            Erziehungsberechtigten keine personenbezogenen Daten an uns
            übermitteln. Wir fordern keine personenbezogenen Daten von Kindern
            und Jugendlichen an. Wissentlich sammeln wir solche Daten nicht und
            geben sie auch nicht an Dritte weiter.
          </p>
          <h5>Google Maps Datenschutzerklärung</h5>
          <p>
            Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre
            Parkway Mountain View, CA 94043, USA) auf unserer Webseite.
          </p>
          <p>
            Durch die Nutzung der Funktionen dieser Karte werden Daten an Google
            übertragen. Welche Daten von Google erfasst werden und wofür diese
            Daten verwendet werden, können Sie auf{" "}
            <a href="https://www.google.com/intl/de/policies/privacy/">
              https://www.google.com/intl/de/policies/privacy/
            </a>{" "}
            nachlesen.
          </p>
          <h5>Facebook Datenschutzerklärung</h5>
          <p>
            Wir verwenden Facebook.com als Social Media Plugin, welches von der
            Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA
            betrieben wird. Gekennzeichnet ist dieses Social Media Plugin mit
            einem Logo von Facebook. Wird das Plugin über diese Webseite
            aufgerufen, baut Ihr Browser eine direkte Verbindung mit den Servern
            von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an
            Ihren Browser übermittelt und von diesem in die Webseite
            eingebunden.
          </p>
          <p>
            Durch das Plugin erhält Facebook die Informationen, welche Facebook
            Seite Ihnen angezeigt werden soll und ordnet Ihren Benutzer, sofern
            Sie bei Facebook eingeloggt sind, automatisch Ihrem Facebook-Konto
            zu.
          </p>
          <p>
            Auch ohne Facebook-Account können Daten wie z.B. IP-Adresse von
            Facebook gespeichert werden. Welche Daten von Facebook erfasst
            werden und wofür diese Daten verwendet werden, können Sie auf{" "}
            <a href="https://de-de.facebook.com/about/privacy/">
              https://de-de.facebook.com/about/privacy/
            </a>{" "}
            nachlesen.
          </p>
          <p>
            Um das Sammeln Ihrer Daten von Facebook über dies Webseite zu
            vermeiden, müssen Sie sich vor dem Besucher dieser Webseite bei
            Facebook ausloggen.
          </p>
          <h5>Links zu Webseiten anderer Anbieter</h5>
          <p>
            Unser Online-Angebot enthält Links zu Webseiten anderer Anbieter.
            Wir haben keinen Einfluss darauf, dass diese Anbieter die
            Datenschutzbestimmungen einhalten.
          </p>
          <h5>Ihre Rechte</h5>
          <p>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
            Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
            Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten
            gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
            Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich
            bei der Aufsichtsbehörde beschweren. In Österreich ist dies die
            Datenschutzbehörde.
          </p>

          <h5>Kontakt</h5>
          <p>Verantwortlich für die Verarbeitungstätigkeit ist das BMAW. </p>
          <i>Sie erreichen uns unter folgenden Kontaktdaten:</i>
          <div className="bmf-data-privacy">Bundesministerium für Finanzen</div>
          <div>Sektion V - Digitalisierung und E-Government</div>
          <div>
            Abteilung 3 - Digitale Gesellschaft und Kompetenzen,
            Projektmarketing und Reporting
          </div>

          <div>Johannesgasse 5, 1010 Wien, Österreich </div>
          <div>
            Tel.:{" "}
            <a
              href="tel:431711000"
              title="Telefonnummer der Reporting Plattform"
            >
              (+43) 1 711 00-0{" "}
            </a>
          </div>
          <div>
            E-Mail:{" "}
            <a
              href="mailto:support@reportingplattform.gv.at"
              title="E-Mailadresse der Reporting Plattform"
            >
              support@reportingplattform.gv.at
            </a>
          </div>
          <div>
            Webseite:{" "}
            <a href="https://www.bmf.gv.at/" title="Webseite des BMF">
              https://www.bmf.gv.at/
            </a>
          </div> */}
        </div>
      </div>
    );
  }
}

export default DataPrivacy;
