import React, { Component } from "react";

import debounce from "lodash.debounce";
import webm from "../images/rpvideo.webm";
import mp4 from "../images/rpvideo.mp4";

class Home extends Component {
  state = {
    videoHeight: window.innerWidth * 0.55 * 0.5625,
  };

  video = React.createRef();

  ratio = 0.5625;

  onResize = () => {
    if (window.innerWidth < 900) {
      this.setState({ videoHeight: window.innerWidth * 0.534 });
    } else this.setState({ videoHeight: window.innerWidth * 0.55 * 0.5625 });
  };

  componentDidMount() {
    window.addEventListener("resize", debounce(this.onResize, 100));
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  render() {
    return (
      <div id="section-home" ref={this.props.refName} className="section home">
        <div className="video-wrapper">
          <video
            height={this.state.videoHeight}
            autoPlay
            loop
            muted
            playsInline
            ref={this.video}
            alt="Dekorative Animation"
            title="Dekorative Animation"
          >
            <source src={mp4} type="video/mp4" />
            <source src={webm} type="video/webm" />
            {/* <source src={mkv} type="video/mkv" /> */}
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="section-home-content">
          <h1>Reporting Plattform</h1>
          <div className="description-wrapper">
            <div className="accent-line" />
            <div className="description">
              <h3>Grundidee</h3>
              <p>
                Mit der Reporting Plattform wurde ein Fundament geschaffen,
                welches die Datenerfassung als auch Visualisierung der Daten der
                Bundesverwaltung bereitstellt. Einzelne, in der Reporting
                Plattform aufgenommene Applikationen, können durch das
                geschaffene Unterkonstrukt auf angekoppelte Fremdsysteme
                zugreifen und Daten aus Schnittstellen für die
                Weiterverarbeitung erfassen.
              </p>
              <p>
                Neben der isolierten Applikationsbereitstellung ist auch eine
                App-2-App Kommunikation für gewünschte Anwendungsfälle möglich.
              </p>
              { /*
              <p>
                Mehr dazu finden Sie auch hier zusammengefasst auf der{" "}
                <a href="https://www.bmf.gv.at/themen/digitalisierung/Digitales-Oesterreich/Digitale-Services-im-Web.html#reporting-plattform">
                  Homepage des BMF
                </a>.
              </p>
              <div className="test-btns-wrapper">
                <button className="lime-btn">Test me now</button>
                <button className="cyan-btn">Power BI Test</button>
              </div>
              */ }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
