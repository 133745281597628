import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import RoadmapHistory from "./RoadmapHistory";

class Roadmap extends Component {

  state = {
    showRoadmapHistory: false,
  };

  showRoadmapHistory = () => {
    this.setState({ showRoadmapHistory: true });
    const history = document.getElementById("history");
    history.classList.remove("d-none");
  };

  render() {
    return (
      <div id="section-roadmap" ref={this.props.refName}>
        <div className="section roadmap">
          <div className="section-roadmap-content">
            <h2>Roadmap</h2>
            <div className="description-wrapper">
              <div className="accent-line" />
              <div className="description">
                <p>
                  Die Reporting Plattform ist im Jahr 2018 entstanden und hat
                  seither immer wieder kleinere und größere Veränderungen
                  durchlebt. Aber auch für die Zukunft sind noch viele neue und
                  spannende Services geplant.
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="timeline-wrapper">
        <VerticalTimeline className="timeline">
            
            <VerticalTimelineElement
              dateClassName="timeline-date date-long"
              date="2025 - geplant"
            />
            
            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
              Informations-Freiheitsgesetz
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Data Governance Act
              </h3>
            </VerticalTimelineElement>
            
            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Konzeption & Umsetzung  Verrechnungsmodell
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Integration weiterer Enterprise Systeme
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                <abbr lang="de" title="Künstliche Intelligenz">AI</abbr> Act
              </h3>
            </VerticalTimelineElement>
            

            <VerticalTimelineElement
              dateClassName="timeline-date date-long"
              date="2024 - projektiert"
            />

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
              Verbesserung im Reporting
              </h3>

                <ul>
                  <li>Automationsgestütztes Reporting</li>
                  <li>Natürlichsprachliches Interface für Anfragen</li> 
                  <li>Natürlichsprachliche Beschreibung von Visualisierungen</li>
                </ul>
              
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Erweiterung Datenprovider
              </h3>
              
              <p className="">
                Generische Integration von <abbr lang="de" title="Software zur Abwicklung sämtlicher Geschäftsprozesse eines Unternehmens">SAP</abbr>
              </p>
              
            </VerticalTimelineElement>


            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
                Erweiterung Betrieb & Zugang
              </h3>
              
              <ul>
                <li>MIC-Umsetzung auf BRZ PaaS</li>
                <li>Self-Service Visualization Concept</li>
              </ul>
              
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Predictive Modelling
              </h3>

            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Erweiterung Datenanalyse Metadaten Plattform
              </h3>
              
              <p className="">
              Austrian Open Data Visualization Hub
              </p>
              
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date date-long"
              date="2023 – realisiert | in Arbeit"
            />

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Predictive Analytics
              </h3>
              <ul>
                <li>KI-Features & Möglichkeiten</li>
                <li>Forecasting Time Series</li>
                <li>Schätzung von ZR-Werten in Zukunft</li>
                <li>Predictive Zugänge</li>
                <li>Prognosen Modelle: Art, Typen Datenquellen</li>
              </ul>

            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
                Anbindung weiterer Datenquellen
              </h3>
              <p>
                u.a. Transport, kritische Güter, Energie und zahlreiche weitere Quellen
              </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-4"
            >
              <h3 className="vertical-timeline-element-title">
                Design Dashboard 
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Microservices
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
                Einbindung Factsheets
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
              Animation Analytische Visualisierung
              </h3>
            </VerticalTimelineElement>


            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
              Leistungsbeschreibung Lebensmittel / Lebensmitteldaten
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-4"
            >
              <h3 className="vertical-timeline-element-title">
                Big Picture Umwelten/Nutzen Stakeholder
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Zentrales Metadaten System (<abbr lang="en" title="Data Ware House">DWH</abbr>)
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-4"
            >
              <h3 className="vertical-timeline-element-title">
              Anwender im BMBWF (Lehrlingsportal)
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Dynamisches, zentralisiertes Job Management
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Daten Qualitätscheck (Shared Service)
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
              Exception Reporting von überraschenden Werten Signalen
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-2"
            >
              <h3 className="vertical-timeline-element-title">
              Barrierefreiheit Maßnahmen
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Public - Open Government Data <abbr lang="en" title="Open Government Data">(OGD)</abbr>
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-3"
            >
              <h3 className="vertical-timeline-element-title">
              What-if Szenarien <abbr lang="en" title="Graphical User Interface">GUI</abbr> Dashboard
              </h3>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              dateClassName="timeline-date"
              className="vertical-timeline-element-1"
            >
              <h3 className="vertical-timeline-element-title">
              Zugriffssteuerung
              </h3>
              <ul>
                <li>Authentifizierung</li>
                <li>Autorisierung - Mobiler Zugriff</li>
                <li><abbr lang="en" title="Microsoft Active Directory Federation Services">ADFS</abbr> Kopplung</li>
                <li>Ablöse PvP-R Profil</li>
                <li>zentralisierte Benutzer-verwaltung</li>
                <li><abbr lang="en" title="Reporting Plattform">RPA</abbr> in Stammportalen</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="d-hide-span"
            >
            <div
              className="d-history"
            >
            <button
              className="cyan-btn"
              onClick={() => this.showRoadmapHistory()}
            >
            <div>
              <span>
              Roadmap 2018-2022
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M278.6 278.6L256 301.3l-22.6-22.6-192-192L18.7 64 64 18.7 86.6 41.4 256 210.7 425.4 41.4 448 18.7 493.3 64 470.6 86.6l-192 192zm0 192L256 493.3l-22.6-22.6-192-192L18.7 256 64 210.7l22.6 22.6L256 402.7 425.4 233.4 448 210.7 493.3 256l-22.6 22.6-192 192z"/></svg>
            
            </div>
          </button>
        </div>
            </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <RoadmapHistory 
      
      >
      </RoadmapHistory>

    </div>
    );
  }
}

export default Roadmap;
