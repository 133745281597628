import React, { Component } from 'react';
import { Times, ArrowLeft, PdfFile } from '../../helpers/svgs';
import ImageModal from './ImageModal';


class ProjectDetails extends Component {
  state = {
    selectedImage: 0,
    showImageModal: false,
    numPages: null,
    pageNumber: 1,
  };

  closeImageModal = () => {
    this.setState({ showImageModal: false });
  };

  showImageModal = (item) => {
    this.setState({ showImageModal: true, selectedImage: item });
  };

  escFunction = (event) => {
    if (event.keyCode === 27 && this.state.showImageModal) {
      this.closeImageModal();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  render() {
    let project = this.props.project;

    let images = project.images.map((item, index) => (
      <img
        key={index}
        src={item}
        alt={project.title}
        onClick={() => this.showImageModal(item)}
      />
    ));

    let fullText = project.fullText?.map((item, index) => (
      <p key={index}>{item}</p>
    ));

    let lists = project.lists?.map((list) => {
      let bulletPoints = list.bulletPoints.map((bP) => <li>{bP}</li>);
      return (
        <div>
          <h4>{list.title}</h4>
          <ul>{bulletPoints}</ul>
        </div>
      );
    });

    let pdfs = project.pdfs?.map((pdf) => (
      <a key={pdf.src} target="_blank" rel="noopener noreferrer" href={pdf.src} className="pdf-file" title="Open Demo-PDF-File">
        {PdfFile} {pdf.title}
      </a>
    ));

    let services = project.services.map((item, index) => (
      <li key={index}>{item}</li>
    ));

    return (
      <div
        className={
          'project-details' + (this.props.visible ? '' : ' hide-to-right')
        }
        role="document"
      >
        <div className="project-details-content">
          <div className="project-details-header">
            <img src={project.logo} alt="logo" />

            <button
              className={this.state.showImageModal ? 'hide' : ''}
              onClick={this.props.closeProjectModal}
              data-dismiss="modal"
              aria-label="Schließen"
            >
              {Times}
            </button>
          </div>
          <h2>{project.title}</h2>
          <div className="description-wrapper">
            <div className="accent-line" />
          </div>

          <div className="project-info">
            <div className="project-text">
              {fullText}

              {lists}

              {project.pdfs ? (
                <div>
                  <h4>Demo Berichte (PDF):</h4> {pdfs}
                </div>
              ) : null}
              <h4>Projekt von:</h4>
              <p>{project.from}</p>

              <h4>Shared Services im Einsatz:</h4>
              <ul>{services}</ul>

              <button
                onClick={this.props.closeProjectModal}
                className="navigate-btn btn-xl"
              >
                {ArrowLeft} Zurück
              </button>
            </div>
            <div className="project-screenshots">
              {images}

              <button
                onClick={this.props.closeProjectModal}
                className="navigate-btn btn-xs"
                data-dismiss="modal"
                aria-label="Schließen"
              >
                {ArrowLeft} Zurück
              </button>
            </div>
          </div>
        </div>

        <ImageModal>
          <div
            role="dialog"
            className={
              'image-modal' + (this.state.showImageModal ? '' : ' hide')
            }
          >
            <button
              className="close-image-modal-btn"
              onClick={this.closeImageModal}
              data-dismiss="modal"
              aria-label="Schließen"
            >
              {Times}
            </button>

            <img src={this.state.selectedImage} alt={project.title} />
          </div>
        </ImageModal>
      </div>
    );
  }
}

export default ProjectDetails;
